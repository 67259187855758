import React, { Component } from 'react'
import Card from 'react-bootstrap/Card'
import Moment from 'react-moment'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { AnimationWrapper } from 'react-hover-animation'
import TextTruncate from 'react-text-truncate'
import { Button } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner'
import removeFromLibraryImg from '../../assets/images/icons/remove-from-library.svg'
import pauseButtonImg from '../../assets/images/icons/pause-circle-outline.svg'
import playButtonImg from '../../assets/images/icons/play-circle-outline.svg'
import goToImg from '../../assets/images/icons/go-to.svg'
import { Link } from 'react-router-dom'

export default class LibraryElement extends Component {

  constructor() {
    super()
    momentDurationFormatSetup(moment)
  }

  state = {
    showDescription: false
  }

  handleClick = e => {
    e.stopPropagation()
    this.setState({
      showDescription: !this.state.showDescription
    })
  }

  removeFromLibrary = (e) => {
    e.stopPropagation()
    this.props.removeFromLibrary(this.props.id, this.props.userId)
  }

  renderDescription = () => {
    if(this.props.description) {
      if(this.state.showDescription) {
        return (
          <div>
            {this.props.description}
            <a href="#/" onClick={this.handleClick}><br/>Hide</a>
          </div>
        )
      } else {
        return (
          <TextTruncate
            line={2}
            truncateText=""
            text={this.props.description}
            textTruncateChild={<a href="#/" onClick={this.handleClick}><br/>... Show more</a>}
          />
        )
      }
    }
  }

  renderSourceEpisode = () => {
    if (this.props.audioType === 'snippet') {
      return (
        <>
          Source episode: {this.props.originalEpisodeName}
          <br/>
        </>
      )
    }
  }

  renderSourceDetails = () => {
    let link = `/podcasts/${this.props.podcastId}`
    return (
      <Card.Text>
        {this.renderSourceEpisode()}
        See more from <Link to={link}>{this.props.podcastName}</Link>
      </Card.Text>
    )
  }

  renderDate = () => {
    if (this.props.audioType === 'snippet') {
      return (
        <>
          {'Snipped '}
          <Moment
            format="MMM Do, YYYY">
            {this.props.createdAt}
          </Moment>
        </>
      )
    } else {
      return (
        <>
          {'Added '}
          <Moment
            format="MMM Do, YYYY">
            {this.props.airDate}
          </Moment>
        </>
      )
    }
  }

  renderTooltip = (type) => {
    if (type === 'remove-from-library') {
      return (
        <Tooltip>
          Remove from library
        </Tooltip>
      )
    } else if (type === 'go-to') {
      return (
        <Tooltip>
          Go to snippet
        </Tooltip>
      )
    }
  }

  renderRemoveFromLibrary = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={this.renderTooltip('remove-from-library')}
      >
        <Button
           onClick={(e) => this.removeFromLibrary(e)}
           variant='link'
           className="pt-0 pb-0"
        >
          <img
            src={removeFromLibraryImg}
            className="remove-from-library"
            alt='delete'
          />
        </Button>
      </OverlayTrigger>
    )
  }

  handleGoToSnipClick = () => {
    let audioElement = document.getElementsByTagName('audio')[0]
    if (audioElement) {
      this.props.pause()
    }
    window.open(this.props.url, '_blank').focus()
  }

  renderGoToSnip = () => {
    if (this.props.audioType === 'snippet') {
      return (
        <OverlayTrigger
          placement="top"
          overlay={this.renderTooltip('go-to')}
        >
          <Button
             onClick={this.handleGoToSnipClick}
             variant="link"
             className="pt-0 pb-0"
          >
            <img
              className="go-to-snippet"
              src={goToImg}
              alt='go to snippet'>
            </img>
          </Button>
        </OverlayTrigger>
      )
    }
  }

  renderAudioTypeIndicator = () => {
    let audioType = this.props.audioType === 'snippet' ? 'snippet' : 'episode'
    return (
      <span className={`audio-type-indicator audio-type-${audioType}`}>
        {audioType}
      </span>
    )
  }

  renderTitle = () => {
    return (
      <Row className="align-items-center">
        <Col className="d-flex justify-content-start">
          {this.props.title}
        </Col>
        <Col xs="auto" className="p-0 d-flex justify-content-end">
          {this.renderGoToSnip()}
        </Col>
        <Col xs="auto" className="p-0 d-flex justify-content-end">
          {this.renderAudioTypeIndicator()}
        </Col>
        <Col xs="auto" className="p-0 d-flex justify-content-end">
          {this.renderRemoveFromLibrary()}
        </Col>
      </Row>
    )
  }

  renderPlayPauseButton = () => {
    const playPauseImg = (this.props.playing && this.props.id === this.props.currentAudioId) ? pauseButtonImg : playButtonImg
    if (this.props.showAudioLoadingSpinner && this.props.id === this.props.currentAudioId) {
      return (
        <div className="episode-play-pause-spinner-container">
          <Spinner
            animation="border"
            role="status"
            className="episode-play-pause"
            as="span"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )
    } else {
      return (
        <Image
          src={playPauseImg}
          onClick={(e) => this.togglePlay(e)}
          alt="play pause button"
          className="episode-play-pause"
        />
      )
    }
  }

  setAndPlayAudio = (shouldPlayOnLoad) => {
    this.props.setAndPlayAudio({
      audioId: this.props.id,
      audioUrl: this.props.audio,
      audioLength: this.props.audioLength,
      title: this.props.title,
      description: this.props.description,
      audioType: this.props.audioType,
      startTime: this.props.startTime,
      stopTime: this.props.stopTime,
      podcastName: this.props.podcastName,
      podcastId: this.props.podcastId,
      ownerId: this.props.userId,
      uuid: this.props.uuid,
      image: this.props.image,
      originalEpisodeName: this.props.originalEpisodeName,
      shouldPlayOnLoad: shouldPlayOnLoad
    })

    if (!shouldPlayOnLoad && this.props.currentAudioId !== this.props.id) {
      this.props.pause()
    }
  }

  togglePlay = e => {
    e.stopPropagation()
    if (this.props.playing && this.props.id === this.props.currentAudioId) {
      this.props.pause()
    } else if (this.props.id === this.props.currentAudioId) {
      this.props.play()
    } else {
      this.setAndPlayAudio(true)
      this.props.discardSnip()
    }
  }

  handleLibraryElementClick = () => {
    this.setAndPlayAudio(false)
  }

  render() {
    let format = this.props.audioLength >= 3600 ? 'HH:mm:ss' : 'mm:ss'
    let audioLength = moment.duration(this.props.audioLength, 'seconds').format(format, { trim: false })

    return (
      <div className = "m-3 episode">
        <AnimationWrapper
          config={{
            color: {
              initial: 'black',
              onHover: '#4e54c8',
            },
          }}
          onClick={this.handleLibraryElementClick}
        >
          <Card className="mb-3">
            <div className="episode-card-container">
              <Row className="align-items-center">
                <Col className="episode-play-pause-container m-0 p-0">
                  {this.renderPlayPauseButton()}
                </Col>
                <Col xs={10} className="episode-card-body-container p-0">
                  <Card.Body className="episode-card-body px-2 py-3">
                    <Card.Title>
                      {this.renderTitle()}
                    </Card.Title>
                    <Card.Text>
                      {this.renderDescription()}
                    </Card.Text>
                      {this.renderSourceDetails()}
                      <Card.Subtitle className="text-muted">
                        <Row>
                          <Col xs={{ span: 4 }} className="pe-0">
                            {audioLength}
                          </Col>
                          <Col className="audio-date ps-0">
                            {this.renderDate()}
                          </Col>
                        </Row>
                      </Card.Subtitle>
                  </Card.Body>
                </Col>
              </Row>
            </div>
          </Card>
        </AnimationWrapper>
      </div>
    )
  }
}
