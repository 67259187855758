import { setAlert } from './Shared'
import { sessionService } from 'redux-react-session'
const snipprApiUrl = `${process.env.REACT_APP_SNIPPR_API_URL}`

export const login = (userObject) => {
  return (dispatch) => {
    if (userObject.email === '' || userObject.password === '') {
      dispatch(setAlert('error', 'Enter your email and password before logging in'))
    } else {
      dispatch({ type: 'LOADING_USER' })
      fetch(`${snipprApiUrl}/sessions/`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({session: userObject})
      })
      .then(response => response.json())
      .then(user => {
        if (user.errors) {
          throw user.errors
        } else {
          sessionService.saveSession(user)
          sessionService.saveUser(user)
          dispatch({ type: 'USER_LOADED' })
        }
      })
      .catch(err => {
        dispatch(setAlert('error', err))
        dispatch({ type: 'ERROR_LOADING_USER' })
      })
    }
  }
}

export const logout = (strategy = null) => {
  return () => {
    sessionService.deleteSession()
    sessionService.deleteUser()
    if (strategy === "facebook" && window.FB) {
      window.FB.logout()
    }
  }
}

export const signup = (userObject) => {
  return (dispatch) => {
    dispatch({ type: 'CREATING_USER' })

    fetch(`${snipprApiUrl}/users/`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({user: userObject})
    })
    .then(response => response.json())
    .then(user => {
      if (user.errors) {
        throw user.errors
      } else {
        sessionService.saveSession(user)
        sessionService.saveUser(user)
        dispatch({ type: 'USER_CREATED' })
      }
    })
    .catch(err => {
      dispatch(setAlert('error', err))
      dispatch({ type: 'ERROR_CREATING_USER' })
    })
  }
}

export const loginWith = (provider, userObject) => {
  let uid, name, email
  if (provider === "google") {
    uid = userObject.profileObj.googleId
    name = userObject.profileObj.name
    email = userObject.profileObj.email
  } else if (provider === "facebook") {
    uid = userObject.userID
    name = userObject.name
    email = userObject.email
  }

  return (dispatch) => {
    dispatch({ type: 'LOADING_USER' })
    fetch(`${snipprApiUrl}/auth/${provider}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        session: {
          uid: uid,
          name: name,
          email: email
        }
      })
    })
    .then(response => response.json())
    .then(user => {
      if (user.errors) {
        throw user.errors
      } else {
        sessionService.saveSession(user)
        sessionService.saveUser({
          ...user,
          authStrategy: provider
        })
        dispatch({ type: 'USER_LOADED' })
      }
    })
    .catch(err => {
      dispatch(setAlert('error', err))
      dispatch({ type: 'ERROR_LOADING_USER' })
    })
  }
}

export const showForgotPasswordModal = () => {
  return (dispatch) => {
    dispatch({ type: 'SHOW_FORGOT_PASSWORD_MODAL' })
  }
}

export const hideForgotPasswordModal = () => {
  return (dispatch) => {
    dispatch({ type: 'HIDE_FORGOT_PASSWORD_MODAL' })
  }
}

export const sendResetPasswordCode = (email) => {
  return (dispatch) => {
    dispatch({ type: 'SENDING_RESET_PASSWORD_CODE', payload: { email: email }})
    fetch(`${snipprApiUrl}/users/forgot_password`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({email: email})
    })
    .then(response => response.json())
    .then(user => {
      if (user.errors) {
        throw user.errors
      }
      dispatch({ type: 'RESET_PASSWORD_CODE_SENT' })
    })
    .catch(err => {
      if (err[0] === 'User not found') {
        dispatch({ type: 'RESET_PASSWORD_USER_NOT_FOUND' })
      }
    })
  }
}

export const verifyResetPasswordCode = (code, email) => {
  return (dispatch) => {
    dispatch({ type: 'VERIFY_RESET_PASSWORD_CODE', payload: { passwordResetCode: code }})
    fetch(`${snipprApiUrl}/users/verify_password_reset_code`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ password_reset_code: code, email: email })
    })
    .then(response => response.json())
    .then(user => {
      if (user.errors) {
        throw user.errors
      }
      dispatch({ type: 'RESET_PASSWORD_CODE_VERIFIED' })
    })
    .catch(err => {
      if (err[0] === 'Incorrect reset code') {
        dispatch({ type: 'RESET_PASSWORD_CODE_INCORRECT' })
      } else if (err[0] === 'Expired reset code') (
        dispatch({ type: 'RESET_PASSWORD_CODE_EXPIRED' })
      )
    })
  }
}

export const updatePassword = (password, passwordConfirmation, code, email) => {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_PASSWORD', payload: { password: password }})
    fetch(`${snipprApiUrl}/users/update_password`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        user: {
          password: password,
          password_confirmation: passwordConfirmation,
          password_reset_code: code,
          email: email
        }
      })
    })
    .then(response => response.json())
    .then(user => {
      if (user.errors) {
        throw user.errors
      }
      dispatch({ type: 'PASSWORD_UPDATED_SUCCESSFULLY' })
    })
    .catch(err => {
      if (err[0] === "Password confirmation doesn't match Password") {
        dispatch({ type: 'PASSWORD_DOES_NOT_MATCH_PASSWORD_CONFIRMATION' })
      } else {
        dispatch({ type: 'PASSWORD_UPDATED_FAILED' })
      }
    })
  }
}

export const clearPasswordResetData = () => {
  return (dispatch) => {
    dispatch({ type: 'CLEAR_PASSWORD_RESET_DATA' })
  }
}

export const invalidateEmail = () => {
  return (dispatch) => {
    dispatch({ type: 'INVALIDATE_EMAIL' })
  }
}

export const validateEmail = () => {
  return (dispatch) => {
    dispatch({ type: 'VALIDATE_EMAIL' })
  }
}

export const invalidatePassword = () => {
  return (dispatch) => {
    dispatch({ type: 'INVALIDATE_PASSWORD' })
  }
}

export const validatePassword = () => {
  return (dispatch) => {
    dispatch({ type: 'VALIDATE_PASSWORD' })
  }
}
