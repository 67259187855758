import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { sessionReducer, sessionService } from 'redux-react-session'
import podcastsReducer from './reducers/podcastsReducer'
import currentAudioReducer from './reducers/currentAudioReducer'
import alertReducer from './reducers/alertReducer'
import libraryReducer from './reducers/libraryReducer'
import appStatusReducer from './reducers/appStatusReducer'
import userReducer from './reducers/userReducer'
import init from './init'
import ReactGA from 'react-ga'

init()

/* Start tracking events with Google Analytics */
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)

const composeEnhancers = ((window.location.href.match(/.*localhost.*/) && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose)

const reducers = {
  session: sessionReducer,
  podcasts: podcastsReducer,
  currentAudio: currentAudioReducer,
  alerts: alertReducer,
  library: libraryReducer,
  appStatus: appStatusReducer,
  user: userReducer
}
const reducer = combineReducers(reducers)

let store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))

sessionService.initSessionService(store)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
