import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import {
  setSnipStartTime,
  setSnipStopTime,
  startSnipping,
  stopSnipping,
  discardSnip
} from 'actions/Audio'
import {
  addSnipToLibrary,
  addEpisodeToLibrary,
  downloadSnip
} from 'actions/Library'
import {
  showShareSnipModal
} from 'actions/Shared'
import { shareSnip } from 'actions/Audio'
import SharingSnippetModalContainer from 'containers/social/SharingSnippetModalContainer'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Spinner from 'react-bootstrap/Spinner'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import downloadSnipImg from '../../assets/images/icons/download-snippet-new.svg'
import startSnipImg from '../../assets/images/icons/start-snip.svg'
import stopSnipImg from '../../assets/images/icons/stop-snip.svg'
import trashCanImg from '../../assets/images/icons/trash-can.svg'
import addEpisodeToLibraryImg from '../../assets/images/icons/add-episode-to-library.svg'
import addSnipToLibraryImg from '../../assets/images/icons/add-snippet-to-library.svg'
import forwardImg from '../../assets/images/icons/forward-10.svg'
import rewindImg from '../../assets/images/icons/rewind-10.svg'
import shareImg from '../../assets/images/icons/share.svg'
import ReactGA from 'react-ga'

class SnippingContainer extends Component {

  handleSnipClick = () => {
    if (this.props.snipStatus === 'NOT_STARTED') {
      this.props.startSnipping()
      this.props.setSnipStartTime(this.props.currentTime)
    } else if (this.props.snipStatus === 'START_TIME_SET') {
      this.props.stopSnipping()
      this.props.setSnipStopTime(this.props.currentTime < this.props.snipStartTime ? this.props.snipStartTime : this.props.currentTime)
    } else {
      this.props.discardSnip()
    }
  }

  handleActionClick = (type) => {
    if (type === 'snippet-library') {
      this.props.addSnipToLibrary(this.props.userId, this.props.title, this.props.audio, this.props.audioLength, this.props.podcastName, this.props.podcastId, this.props.src, this.props.snipStartTime, this.props.snipStopTime, this.props.image)
    } else if (type === 'snippet-download') {
      if (this.props.audioType === 'snippet') {
        this.props.downloadSnip(this.props.title, this.props.audio.split('#t=')[0], this.props.audioLength, this.props.startTime, this.props.stopTime)
      } else {
        this.props.downloadSnip(this.props.title, this.props.audio, this.props.audioLength, this.props.snipStartTime, this.props.snipStopTime)
      }
      ReactGA.event({
        category: 'Snippet',
        action: 'download-click',
        label: this.props.userId
      })
    } else if (type === 'episode-library') {
      this.props.addEpisodeToLibrary({
        userId: this.props.userId,
        title: this.props.title,
        description: this.props.description,
        audio: this.props.audio,
        audioLength: this.props.audioLength,
        podcastName: this.props.podcastName,
        podcastId: this.props.podcastId,
        rawSrc: this.props.src,
        image: this.props.image
      })
    } else if (type === 'snippet-share') {
      if (this.props.uuid) {
        /* snippet has already been generated; just need to show the modal again */
        this.props.showShareSnipModal()
      } else {
        this.props.shareSnip(this.props.userId, this.props.title, this.props.audio, this.props.audioLength, this.props.podcastName, this.props.podcastId, this.props.src, this.props.snipStartTime, this.props.snipStopTime, this.props.image, this.props.originalEpisodeName)
        ReactGA.event({
          category: 'Snippet',
          action: 'share-click',
          label: this.props.userId
        })
      }
    }
  }

  disableSnipOption = () => {
    if (this.props.snipStatus === 'STOP_TIME_SET' || this.props.audioType === 'snippet') {
      return false
    } else {
      return true
    }
  }

  renderTooltip = (type, text) => {
    let snipToolTipText
    if (type === 'snippet') {
      snipToolTipText = text
    } else if (type === 'add-to-library') {
      snipToolTipText = 'Log in to add snippets and episodes to your library'
    } else if (type === 'snippet-download') {
      snipToolTipText = 'Download snippet'
    } else if (type === 'snippet-share') {
      snipToolTipText = 'Share snippet'
    }

    return (
      <Tooltip className="button-tooltip">
        {snipToolTipText}
      </Tooltip>
    )
  }

  renderDownloadSnipButton = () => {
    let img
    if (this.props.downloading) {
      img =
      <>
        <Spinner
          as="span"
          animation="border"
          role="status"
          size="md"
          aria-hidden="true"
          height="auto"
        />
        <span className="visually-hidden">Loading...</span>
      </>
    } else {
      img =
        <img
          className='button'
          src={downloadSnipImg}
          alt='download snippet'>
        </img>
    }

    return (
      <OverlayTrigger
        placement="top"
        overlay={this.renderTooltip('snippet-download')}
      >
        <div>
          <Button
            variant="outline-secondary"
            className="m-2 p-1 button-container"
            disabled={this.disableSnipOption()}
            onClick={() => this.handleActionClick('snippet-download')}
          >
            {img}
          </Button>
        </div>
      </OverlayTrigger>
    )
  }

  renderSnipButton = () => {
    let snipImage
    let snipToolTipText

    if (this.props.snipStatus === 'NOT_STARTED') {
      snipImage = startSnipImg
      snipToolTipText = 'Start snipping'
    } else if (this.props.snipStatus === 'START_TIME_SET') {
      snipImage = stopSnipImg
      snipToolTipText = 'Stop snipping'
    } else {
      snipImage = trashCanImg
      snipToolTipText = 'Discard snip'
    }

    if (this.props.audioType !== 'snippet') {
      return (
        <OverlayTrigger
          placement="top"
          overlay={this.renderTooltip('snippet', snipToolTipText)}
        >
          <div>
            <Button
               variant="outline-secondary"
               className="m-2 p-1 button-container"
               onClick={this.handleSnipClick}
            >
              <img
                className='button'
                src={snipImage}
                alt='snip button'>
              </img>
            </Button>
          </div>
        </OverlayTrigger>
      )
    }
  }

  renderDropdownButton = () => {
    let addEpisodeToLibrary
    if (this.props.audioType !== 'library-episode') {
      addEpisodeToLibrary =
        <Dropdown.Item
          eventKey="2"
          onClick={() => this.handleActionClick('episode-library')}
        >
          Add episode to library
        </Dropdown.Item>
    }

    return (
      <DropdownButton
          drop="up"
          variant="outline-secondary"
          disabled={!this.props.authenticated}
          className="m-2 p-0 button-container"
          title={
            <img
              className='button'
              src={addEpisodeToLibraryImg}
              alt='add episode to library'>
            </img>
          }
        >
        <Dropdown.Item
          eventKey="1"
          disabled={this.disableSnipOption()}
          onClick={() => this.handleActionClick('snippet-library')}
        >
          Add snippet to library
        </Dropdown.Item>
        {addEpisodeToLibrary}
      </DropdownButton>
    )
  }

  renderAddToLibrary = () => {
    if (this.props.audioType !== 'snippet') {
      if (this.props.authenticated) {
        return (
          this.renderDropdownButton()
        )
      } else {
        return (
          <OverlayTrigger
            placement="top"
            overlay={this.renderTooltip('add-to-library')}
          >
            {this.renderDropdownButton()}
          </OverlayTrigger>
        )
      }
    }
  }

  renderAddSnippetToLibrary = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={this.renderTooltip('snippet-library')}
      >
        <div>
          <Button
             variant="outline-secondary"
             className="m-2 p-1 button-container"
             disabled={this.disableSnipOption() || !this.props.authenticated}
             onClick={() => this.handleActionClick('snippet-library')}
          >
            <img
              className='button'
              src={addSnipToLibraryImg}
              alt='add snippet to library'>
            </img>
          </Button>
        </div>
      </OverlayTrigger>
    )
  }

  renderForwardButton = () => {
    return (
      <div id="forward-10-button">
        <input
          type="image"
          src={forwardImg}
          onClick={this.props.handleFastForward}
          alt="fast forward"
        />
      </div>
    )
  }

  renderRewindButton = () => {
    return (
      <div id="rewind-10-button">
        <input
          type="image"
          src={rewindImg}
          onClick={this.props.handleRewind}
          alt="rewind"
        />
      </div>
    )
  }

  renderEpisodeName = () => {
    return (
      <div id="current-audio-podcast-name">
        {this.props.podcastName} - {this.props.title}
      </div>
    )
  }

  renderShareButton = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={this.renderTooltip('snippet-share')}
      >
        <div>
          <Button
             variant="outline-secondary"
             className="m-2 p-1 button-container"
             disabled={this.disableSnipOption()}
             onClick={() => this.handleActionClick('snippet-share')}
          >
            <img
              className='button'
              src={shareImg}
              alt='share snippet'>
            </img>
          </Button>
        </div>
      </OverlayTrigger>
    )
  }

  renderShareSnipModal = () => {
    return (
      <SharingSnippetModalContainer/>
    )
  }

  render() {
    return (
      <div>
        {this.renderEpisodeName()}
        <div className="mt-1 snipping-container">
          {this.renderRewindButton()}
          {this.renderSnipButton()}
          {this.renderShareButton()}
          {this.renderDownloadSnipButton()}
          {this.renderAddToLibrary()}
          {this.renderForwardButton()}
        </div>
        {this.renderShareSnipModal()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.session.authenticated,
    userId: state.session.user.id,
    downloading: state.alerts.downloading,
    title: state.currentAudio.title,
    audioLength: state.currentAudio.audioLength,
    snipStartTime: state.currentAudio.snipStartTime,
    snipStopTime: state.currentAudio.snipStopTime,
    podcastName: state.currentAudio.podcastName,
    podcastId: state.currentAudio.podcastId,
    description: state.currentAudio.description,
    audio: state.currentAudio.audioUrl,
    src: state.currentAudio.audioUrl,
    snipping: state.currentAudio.snipping,
    snipStatus: state.currentAudio.snipStatus,
    startTime: state.currentAudio.startTime,
    stopTime: state.currentAudio.stopTime,
    uuid: state.appStatus.uuid,
    image: state.currentAudio.image,
    originalEpisodeName: state.currentAudio.originalEpisodeName,
    library: state.library.library
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startSnipping: () => dispatch(startSnipping()),
    stopSnipping: () => dispatch(stopSnipping()),
    discardSnip: () => dispatch(discardSnip()),
    setSnipStartTime: (startTime) => dispatch(setSnipStartTime(startTime)),
    setSnipStopTime: (stopTime) => dispatch(setSnipStopTime(stopTime)),
    addSnipToLibrary: (userId, title, audio, audioLength, podcastName, podcastId, rawSrc, snipStartTime, snipStopTime, image) => dispatch(addSnipToLibrary(userId, title, audio, audioLength, podcastName, podcastId, rawSrc, snipStartTime, snipStopTime, image)),
    shareSnip: (userId, title, audio, audioLength, podcastName, podcastId, rawSrc, snipStartTime, snipStopTime, image, originalEpisodeName) => dispatch(shareSnip(userId, title, audio, audioLength, podcastName, podcastId, rawSrc, snipStartTime, snipStopTime, image, originalEpisodeName)),
    addEpisodeToLibrary: (...args) => dispatch(addEpisodeToLibrary(...args)),
    downloadSnip: (title, audio, audioLength, snipStartTime, snipStopTime) => dispatch(downloadSnip(title, audio, audioLength, snipStartTime, snipStopTime)),
    showShareSnipModal: () => dispatch(showShareSnipModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnippingContainer)
