import React, { Component } from 'react'
import { connect } from 'react-redux'
import SocialShareButtons from 'components/social/SocialShareButtons'

class SocialShareButtonsContainer extends Component {
  render() {
    return (
      <SocialShareButtons
        shareUrl={this.props.shareUrl}
        podcastName={this.props.podcastName}
      >
      </SocialShareButtons>
    )
  }
}

const mapStateToProps = state => {
  return {
    podcastName: state.currentAudio.podcastName
  }
}

const mapDispatchToProps = dispatch => {
  return null
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialShareButtonsContainer)
