import React, { Component } from 'react'
import Alert from 'react-bootstrap/Alert'

export default class AlertComponent extends Component {

  handleClose = () => {
    this.props.hideAlert()
  }

  renderErrorMessage = () => {
    if (this.props.message.length > 1) {
      const listItems = this.props.message.map((message, key) => <li key={key}>{message}</li>)
      return (
        <ul className="mb-0">
          {listItems}
        </ul>
      )
    } else {
      return (
        this.props.message[0]
      )
    }
  }

  render() {
    let variant = this.props.status === 'success' ? 'success' : 'danger'
    return (
      <Alert
        className='sticky-top'
        variant={variant}
        onClose={this.handleClose}
        dismissible
      >
        {this.renderErrorMessage()}
      </Alert>
    )
  }
}
