import React, { Component } from 'react'
import { connect } from 'react-redux'
import PasswordValidator from 'components/user/PasswordValidator'
import { invalidatePassword, validatePassword } from 'actions/User'
var validator = require('password-validator')

class PasswordValidatorContainer extends Component {
  state = {
    validations: []
  }

  constructor() {
    super()
    let passwordValidator = new validator()

    passwordValidator
    .is().min(8)                                    // Minimum length 8
    .is().max(100)                                  // Maximum length 100
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().digits()                                 // Must have digits
    .has().not().spaces()                           // Must have spaces
    .has().symbols()                                // Must have special characters

    this.passwordSchema = passwordValidator
  }

  componentDidUpdate(prevProps, prevState) {
    let passwordConfirmationValidator = new validator()
    passwordConfirmationValidator
    .is().oneOf([this.props.password], 'Password confirmation must match password')

    this.passwordConfirmationSchema = passwordConfirmationValidator
    let passwordValidations = this.passwordSchema.validate(this.props.password, { details: true })
    let passwordConfirmationValidations = this.passwordConfirmationSchema.validate(this.props.passwordConfirmation, { details: true })

    let allValidations = [
      ...passwordValidations,
      ...passwordConfirmationValidations
    ]

    if (JSON.stringify(allValidations) !== JSON.stringify(this.state.validations)) {
      this.setState({
        ...this.state,
        validations: allValidations
      }, () => {
        if (prevState.validations.length > 0 && this.state.validations.length === 0) {
          this.props.validatePassword()
        } else if (this.state.validations.length > 0) {
          this.props.invalidatePassword()
        }
      })
    }
  }

  render() {
    return (
      <PasswordValidator
        validations={this.state.validations}
        passwordIsValid={this.props.passwordIsValid}
        passwordChecked={this.props.passwordChecked}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    passwordIsValid: state.user.passwordIsValid,
    passwordChecked: state.user.passwordChecked
  }
}

const mapDispatchToProps = dispatch => {
  return {
    invalidatePassword: () => dispatch(invalidatePassword()),
    validatePassword: () => dispatch(validatePassword())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordValidatorContainer)
