import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideShareSnipModal, showShareSnipModal, clearSnipUuid } from 'actions/Shared'
import { pause } from 'actions/Audio'
import SharingSnippetModal from 'components/social/SharingSnippetModal'

class SharingSnippetModalContainer extends Component {

  render() {
    if (this.props.showStatus && this.props.statusType === 'SHARING') {
      return (
        <SharingSnippetModal
          show={true}
          url={`${window.location.protocol}//${window.location.host}/snippets/${this.props.uuid}`}
          hideShareSnipModal={this.props.hideShareSnipModal}
          showShareSnipModal={this.props.showShareSnipModal}
          audioId={this.props.audioId}
          audioUrl={this.props.audioUrl}
          snipStartTime={this.props.snipStartTime}
          snipStopTime={this.props.snipStopTime}
          clearSnipUuid={this.props.clearSnipUuid}
          pause={this.props.pause}
          authenticated={this.props.authenticated}
        />
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => {
  return {
    status: state.appStatus.status,
    statusType: state.appStatus.statusType,
    showStatus: state.appStatus.showStatus,
    uuid: state.appStatus.uuid,
    audioId: state.currentAudio.audioId,
    audioUrl: state.currentAudio.audioUrl,
    snipStartTime: state.currentAudio.snipStartTime,
    snipStopTime: state.currentAudio.snipStopTime,
    authenticated: state.session.authenticated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideShareSnipModal: () => dispatch(hideShareSnipModal()),
    showShareSnipModal: () => dispatch(showShareSnipModal()),
    clearSnipUuid: () => dispatch(clearSnipUuid()),
    pause: () => dispatch(pause())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SharingSnippetModalContainer)
