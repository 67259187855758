import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import CloseButton from 'react-bootstrap/CloseButton'
import InputGroup from 'react-bootstrap/InputGroup'

export default class SearchPodcastsInput extends Component {

  state = {
    searchInput: ""
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchInput !== prevProps.searchInput || this.props.offset !== prevProps.offset) {
      if (this.props.searchInput !== "") {
        this.props.fetchPodcasts(this.props.searchInput, this.props.offset)
      }
    }
  }

  handleChange = event => {
    this.setState({
      ...this.state,
      searchInput: event.target.value
    }, () => {
      /* this change triggers a change in state, which forces this whole component
      to re-render and submit another fetchPodcasts request via componentDidUpdate.
      This is necessary for pagination purposes, to prevent a prevPage or nextPage
      click from having to keep track of the searchInput */
      this.props.updateSearchInput(this.state.searchInput)
    })
  }

  handleClearInputClick = event => {
    this.props.clearSearchInput()
    document.getElementById('search-podcasts').focus()
  }

  render() {
    return (
      <Form
        className="m-4"
        onSubmit={e => { e.preventDefault() }}
      >
        <Form.Group controlId="search-podcasts">
          <InputGroup>
            <Form.Control
              type="text"
              className="center"
              placeholder="Search podcasts"
              onChange={this.handleChange}
              value={this.props.searchInput}
              style={{backgroundColor: "white", borderRight: "none"}}
            />
            <InputGroup.Text
              style={{backgroundColor: "white"}}
            >
              <CloseButton
                onClick={this.handleClearInputClick}
              />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Form>
    )
  }
}
