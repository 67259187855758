export const hideAlert = () => {
  return (dispatch) => {
    dispatch({type: 'HIDE_ALERT'})
  }
}

export const setAlert = (status, notification) => {
  return (dispatch) => {
    let messages = []
    if (typeof notification === "string") {
      messages.push(notification)
    } else {
      notification.forEach(n => messages.push(n))
    }

    dispatch({type: 'SET_ALERT', payload: {
      status: status,
      message: messages
    }})
  }
}

export const showShareSnipModal = () => {
  return (dispatch) => {
    dispatch({type: 'SHOW_SNIP'})
  }
}

export const hideShareSnipModal = () => {
  return (dispatch) => {
    dispatch({type: 'DISMISS_SNIP'})
  }
}

export const clearSnipUuid = () => {
  return (dispatch) => {
    dispatch({type: 'CLEAR_SNIP_UUID'})
  }
}
