import React, { Component } from 'react'
import SignUpForm from 'components/user/SignUpForm'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signup } from 'actions/User'


export class SignUpContainer extends Component {
  render() {
    if (this.props.authenticated) {
      return <Redirect to='/' />
    } else {
      return (
        <SignUpForm
          signup={this.props.signup}
          disableSignUpButton={this.props.disableSignUpButton}
        />
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.session.authenticated,
    disableSignUpButton: (state.user.emailChecked && !state.user.emailIsValid) || (state.user.passwordChecked && !state.user.passwordIsValid) || (state.user.emailChecked && !state.user.passwordChecked) || (state.user.passwordChecked && !state.user.emailChecked)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signup: (user) => dispatch(signup(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer)
