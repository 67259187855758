import React, { Component } from 'react'
import { connect } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Row'
import FacebookLoginButton from 'components/user/FacebookLoginButton'
import GoogleLoginButton from 'components/user/GoogleLoginButton'
import { loginWith } from 'actions/User'

class OauthContainer extends Component {
  render() {
    return (
      <Container className="mt-5">
        <hr className="oauth-divider"/>
        <Col className="mt-5">
          <FacebookLoginButton
            appId={this.props.facebookAppId}
            loginWith={this.props.loginWith}
          />
          <GoogleLoginButton
            appId={this.props.googleAppId}
            loginWith={this.props.loginWith}
          />
        </Col>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
    googleAppId: process.env.REACT_APP_GOOGLE_APP_ID
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loginWith: (provider, user) => dispatch(loginWith(provider, user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OauthContainer)
