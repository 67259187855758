import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import linkImg from '../../assets/images/icons/link.svg'
import goToImg from '../../assets/images/icons/go-to.svg'
import SocialShareButtonsContainer from '../../containers/social/SocialShareButtonsContainer'


export default class SharingSnippetModal extends Component {

  state = {
    copyToClipboardClicked: false
  }

  componentDidUpdate(prevProps) {
    /* Reset snip UUID in the event the start/stop time or audio changes so we can generate a new snippet */
    if (this.props.audioUrl !== prevProps.audioUrl || this.props.snipStartTime !== prevProps.snipStartTime || this.props.snipStopTime !== prevProps.snipStopTime) {
      this.props.clearSnipUuid()
    }
  }

  handleClose = () => {
    this.props.hideShareSnipModal()
  }

  handleCopyClick = () => {
    this.setState({
      ...this.state,
      copyToClipboardClicked: true
    }, () => {
      navigator.clipboard.writeText(this.props.url)
    })
  }

  handleGoToSnipClick = () => {
    this.props.pause()
    window.open(this.props.url, '_blank').focus()
  }

  renderTooltip = (type) => {
    let tooltipText
    if (type === 'copy') {
      if (this.state.copyToClipboardClicked) {
        tooltipText = "Copied!"
      } else {
        tooltipText = "Copy link"
      }
    } else if (type === 'go-to') {
      tooltipText = "Go to snippet"
    }

    return (
      <Tooltip>
        {tooltipText}
      </Tooltip>
    )
  }

  renderCopyToClipboard = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={this.renderTooltip('copy')}
      >
        <div>
          <Button
             variant="outline-secondary"
             onClick={this.handleCopyClick}
             className="p-1"
             id="button-addon2"
          >
            <img
              style={{width: 25}}
              src={linkImg}
              alt='copy link'>
            </img>
          </Button>
        </div>
      </OverlayTrigger>
    )
  }

  renderGoToSnip = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={this.renderTooltip('go-to')}
      >
        <div>
          <Button
             variant="outline-secondary"
             onClick={this.handleGoToSnipClick}
             className="p-1"
             id="button-addon2"
          >
            <img
              style={{width: 25}}
              src={goToImg}
              alt='go to snippet'>
            </img>
          </Button>
        </div>
      </OverlayTrigger>
    )
  }

  renderFooter = () => {
    if (this.props.authenticated) {
      return (
        <Modal.Footer
          style={{justifyContent: "center"}}
          className="fw-light text-muted"
        >
          This snippet has automatically been added to your library.
        </Modal.Footer>
      )
    }
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Share snippet</Modal.Title>
        </Modal.Header>
          <Modal.Body
            className="px-4 pt-4 pb-0"
          >
            <InputGroup className="align-items-center">
              <FormControl
                value={this.props.url}
                readOnly
              />
              {this.renderCopyToClipboard()}
              {this.renderGoToSnip()}
            </InputGroup>
            <SocialShareButtonsContainer
              shareUrl={this.props.url}
            />
          </Modal.Body>
          {this.renderFooter()}
      </Modal>
    )
  }
}
