import React, { Component } from 'react'
import { connect } from 'react-redux'
import EmailValidator from 'components/user/EmailValidator'
import { invalidateEmail, validateEmail } from 'actions/User'

class EmailValidatorContainer extends Component {

  state = {
    validations: []
  }

  validateEmail = (email) => {
    if (
      String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true
    } else {
      return false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.email !== prevProps.email) {
      let isValidEmail = this.validateEmail(this.props.email)
      if (isValidEmail) {
        this.setState({
          ...this.state,
          validations: []
        }, () => {
          this.props.validateEmail()
        })
      } else {
        this.setState({
          ...this.state,
          validations: ['Email must be valid']
        }, () => {
          this.props.invalidateEmail()
        })
      }
    }
  }

  render() {
    return (
      <EmailValidator
        validations={this.state.validations}
        emailIsValid={this.props.emailIsValid}
        emailChecked={this.props.emailChecked}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    emailIsValid: state.user.emailIsValid,
    emailChecked: state.user.emailChecked
  }
}

const mapDispatchToProps = dispatch => {
  return {
    invalidateEmail: () => dispatch(invalidateEmail()),
    validateEmail: () => dispatch(validateEmail())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailValidatorContainer)
