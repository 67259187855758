const initialState = {
  library: null
}

export default function libraryReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case 'CLEAR_LIBRARY_STATE':
      return {
        ...initialState
      }

    case 'FETCH_LIBRARY':
      return {
        ...state,
        library: action.payload.library,
        status: 'LOADED'
      }

    case 'START_FETCHING_LIBRARY':
      return {
        ...state,
        status: 'LOADING'
      }

    case 'INJECT_AUDIO_ELEMENT_INTO_LIBRARY':
      let newLib = [...state.library]
      newLib.unshift(action.payload.audioElement)

      return {
        ...state,
        library: newLib
      }

    default:
      return state
  }
}
