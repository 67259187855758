import React, { Component } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Spinner from 'react-bootstrap/Spinner'
import Moment from 'react-moment'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { AnimationWrapper } from 'react-hover-animation'
import TextTruncate from 'react-text-truncate'
import pauseButtonImg from '../../assets/images/icons/pause-circle-outline.svg'
import playButtonImg from '../../assets/images/icons/play-circle-outline.svg'


export default class Episode extends Component {

  constructor() {
    super()
    momentDurationFormatSetup(moment)
  }

  state = {
    showDescription: false
  }

  handleClick = e => {
    e.stopPropagation()
    this.setState({
      showDescription: !this.state.showDescription
    })
  }

  setAndPlayAudio = (shouldPlayOnLoad) => {
    this.props.setAndPlayAudio({
      audioId: this.props.id,
      audioUrl: this.props.audio,
      audioLength: this.props.audioLength,
      title: this.props.title,
      description: this.props.description,
      audioType: this.props.audioType,
      startTime: this.props.startTime,
      stopTime: this.props.stopTime,
      podcastName: this.props.podcastName,
      podcastId: this.props.podcastId,
      image: this.props.image,
      originalEpisodeName: this.props.title,
      shouldPlayOnLoad: shouldPlayOnLoad
    })

    if (!shouldPlayOnLoad && this.props.currentAudioId !== this.props.id) {
      this.props.pause()
    }
  }

  togglePlay = e => {
    e.stopPropagation()
    if (this.props.playing && this.props.id === this.props.currentAudioId) {
      this.props.pause()
    } else if (this.props.id === this.props.currentAudioId) {
      this.props.play()
    } else {
      this.setAndPlayAudio(true)
      this.props.discardSnip()
    }
  }

  renderPlayPauseButton = () => {
    const playPauseImg = (this.props.playing && this.props.id === this.props.currentAudioId) ? pauseButtonImg : playButtonImg
    if (this.props.showAudioLoadingSpinner && this.props.id === this.props.currentAudioId) {
      return (
        <div className="episode-play-pause-spinner-container">
          <Spinner
            animation="border"
            role="status"
            className="episode-play-pause"
            as="span"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )
    } else {
      return (
        <Image
          src={playPauseImg}
          onClick={(e) => this.togglePlay(e)}
          alt="play pause button"
          className="episode-play-pause"
        />
      )
    }
  }

  renderDescription = () => {
    if(this.props.description) {
      if(this.state.showDescription) {
        return (
          <div>
            {this.props.description}
            <a href="#/" onClick={this.handleClick}><br/>Hide</a>
          </div>
        )
      } else {
        return (
          <TextTruncate
            line={2}
            truncateText=""
            text={this.props.description}
            textTruncateChild={<a href="#/" onClick={this.handleClick}><br/>... Show more</a>}
            element="span"
          />
        )
      }
    }
  }

  handleCardClick = () => {
    this.setAndPlayAudio(false)
  }

  render() {
    let format = this.props.audioLength >= 3600 ? 'HH:mm:ss' : 'mm:ss'
    let audioLength = moment.duration(this.props.audioLength, 'seconds').format(format, { trim: false })

    return (
      <div className = "m-3 episode">
      <AnimationWrapper
        config={{
          color: {
            initial: 'black',
            onHover: '#4e54c8',
          },
        }}
        onClick={this.handleCardClick}
      >
        <Card className="mb-3">
          <div className="episode-card-container">
            <Row className="align-items-center">
              <Col className="episode-play-pause-container m-0 p-0">
                {this.renderPlayPauseButton()}
              </Col>
              <Col xs={10} className="episode-card-body-container p-0">
                <Card.Body className="episode-card-body px-2 py-3">
                  <Card.Title>{this.props.title}</Card.Title>
                  <Card.Text>
                    {this.renderDescription()}
                  </Card.Text>
                    <Card.Subtitle className="text-muted">
                      <Row>
                        <Col xs={{ span: 4 }} className="pe-0">
                          {audioLength}
                        </Col>
                        <Col className="audio-date ps-0">
                          {'Aired '}
                          <Moment
                            format="MMM Do, YYYY"
                            class
                          >
                            {this.props.airDate}
                          </Moment>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                </Card.Body>
              </Col>
            </Row>
          </div>
        </Card>
      </AnimationWrapper>
      </div>
    )
  }
}
