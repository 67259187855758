import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'
import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import NavBarContainer from './containers/shared/NavBarContainer'
import AlertContainer from './containers/shared/AlertContainer'
import SignUpContainer from './containers/user/SignUpContainer'
import LogInContainer from './containers/user/LogInContainer'
import LogOutContainer from './containers/user/LogOutContainer'
import SearchPodcastsContainer from './containers/search/SearchPodcastsContainer'
import PodcastContainer from './containers/search/PodcastContainer'
import LibraryContainer from './containers/library/LibraryContainer'
import AudioContainer from './containers/audio/AudioContainer'
import SharedSnippetContainer from './containers/audio/SharedSnippetContainer'
import Home from './components/Home'
import RouteChangeTracker from './tracking/routes/RouteChangeTracker'
import { createBrowserHistory } from 'history'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'stylesheets/Audio.css'
import 'stylesheets/Library.css'
import 'stylesheets/Search.css'
import 'stylesheets/Shared.css'
import 'stylesheets/User.css'

var history = createBrowserHistory()


class App extends Component {

  renderMain = () => {
    return (
      <div id="snippr-container">
        <div id="snippr-body">
          <NavBarContainer />
          <AlertContainer />
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={LogInContainer} />
          <Route exact path="/signup" component={SignUpContainer} />
          <Route exact path="/logout" component={LogOutContainer} />
          <Route exact path="/search" component={SearchPodcastsContainer} />
          <Route exact path="/library" component={LibraryContainer} />
          <Route path="/podcasts/:podcastId" component={PodcastContainer} />
          <Route path="/snippets/:snippetId" component={SharedSnippetContainer} />
        </div>
        <div id="snippr-audio-container">
          <AudioContainer />
        </div>
        <RouteChangeTracker history={history} />
      </div>
    )
  }

  render() {
    return (
      <Router>
        <LoadingOverlay
          active={this.props.showStatus && this.props.statusType !== 'SHARING'}
          spinner
          text={this.props.message}
        >
          {this.renderMain()}
        </LoadingOverlay>
      </Router>
    )
  }
}

const mapStateToProps = state => {
  return {
    status: state.appStatus.status,
    showStatus: state.appStatus.showStatus,
    message: state.appStatus.message,
    statusType: state.appStatus.statusType
  }
}

export default connect(mapStateToProps)(App)
