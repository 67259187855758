const initialState = {
  playing: false,
  showing: false,
  expanded: true,
  snipping: false,
  snipStatus: 'NOT_STARTED',
  loading: false,
  snipStartTime: null,
  snipStopTime: null,
  playedThrough: false,
  showAudioLoadingSpinner: false
}

export default function currentAudioReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case 'CLEAR_AUDIO_STATE':
      return {
        ...initialState
      }

    case 'SET_AUDIO':
      return {
        ...state,
        audioId: action.payload.audioId,
        audioUrl: action.payload.audioUrl,
        audioLength: action.payload.audioLength,
        title: action.payload.title,
        description: action.payload.description,
        audioType: action.payload.audioType,
        startTime: action.payload.startTime,
        stopTime: action.payload.stopTime,
        podcastName: action.payload.podcastName,
        podcastId: action.payload.podcastId,
        ownerId: action.payload.ownerId,
        uuid: action.payload.uuid,
        image: action.payload.image,
        originalEpisodeName: action.payload.originalEpisodeName,
        shouldPlayOnLoad: action.payload.shouldPlayOnLoad
      }

    case 'SET_SNIP_START_TIME':
      return {
        ...state,
        snipStartTime: action.payload.snipStartTime
      }

    case 'SET_SNIP_STOP_TIME':
      return {
        ...state,
        snipStopTime: action.payload.snipStopTime
      }

    case 'DISCARD_SNIP':
      return {
        ...state,
        snipping: false,
        snipStatus: 'NOT_STARTED',
        snipStartTime: null,
        snipStopTime: null
      }

    case 'START_SNIPPING':
      return {
        ...state,
        snipping: true,
        snipStatus: 'START_TIME_SET'
      }

    case 'STOP_SNIPPING':
      return {
        ...state,
        snipping: true,
        snipStatus: 'STOP_TIME_SET'
      }

    case 'SHOW_AUDIO_CONTAINER':
      return {
        ...state,
        showing: true
      }

    case 'PLAY':
      return {
        ...state,
        playing: true,
      }

    case 'PAUSE':
      return {
        ...state,
        playing: false
      }

    case 'EXPAND':
      return {
        ...state,
        expanded: true
      }

    case 'COLLAPSE':
      return {
        ...state,
        expanded: false
      }

    case 'UPDATE_AUDIO_DURATION':
      return {
        ...state,
        audioLength: action.payload.audioLength
      }

    case 'START_LOADING':
      return {
        ...state,
        loading: true
      }

    case 'STOP_LOADING':
      return {
        ...state,
        loading: false,
        showAudioLoadingSpinner: false
      }

    case 'UPDATE_PLAYED_THROUGH':
      return {
        ...state,
        playedThrough: true
      }

    case 'UPDATE_SHOULD_PLAY_ON_LOAD':
      return {
        ...state,
        shouldPlayOnLoad: true
      }

    case 'PLAY_BUTTON_CLICKED':
      return {
        ...state,
        showAudioLoadingSpinner: true,
        audioId: action.payload.audioId
      }

    default:
      return state
  }
}
