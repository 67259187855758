import { setAlert } from './Shared'
import { createSnip, populateSnipUuid } from './Audio'
var contentDisposition = require('content-disposition')
const snipprApiUrl = `${process.env.REACT_APP_SNIPPR_API_URL}`

export const addEpisodeToLibrary = ({userId, title, description, audio, audioLength, podcastName, podcastId, rawSrc, image} = {}) => {
  return (dispatch) => {
    dispatch({ type: 'ADD_EPISODE_TO_LIBRARY' })

    fetch(`${snipprApiUrl}/users/${userId}/episodes`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        user_id: userId,
        title: title,
        description: description,
        audio: audio,
        audio_length_sec: audioLength,
        podcast_name: podcastName,
        podcast_id: podcastId,
        raw_src: rawSrc,
        image: image
      })
    })
    .then(response => response.json())
    .then(episode => {
      if(episode.errors) {
        dispatch(setAlert('error', episode.errors))
      } else {
        dispatch(setAlert('success', 'Episode added to library'))
        dispatch(injectAudioElementIntoLibrary(episode))
      }
    })
  }
}

export const injectAudioElementIntoLibrary = (audioElement) => {
  return (dispatch) => {
    dispatch({type: 'INJECT_AUDIO_ELEMENT_INTO_LIBRARY', payload: {
      audioElement: audioElement
    }})
  }
}

export const addSnipToLibrary = (...args) => {
  return (dispatch) => {
    dispatch({type: 'ADD_SNIP_TO_LIBRARY'})
    return (createSnip(...args))
    .then(resp => resp.json())
    .then(snip => {
      if(snip.errors) {
        dispatch(setAlert('error', snip.errors))
      } else {
        dispatch(setAlert('success', 'Snippet added to library'))
        dispatch(injectAudioElementIntoLibrary(snip))
      }
    })
  }
}

export const retrieveLibrary = (userId) => {
  let url = `${snipprApiUrl}/users/${userId}/library`
  return fetch(url)
}

export const fetchLibrary = (userId) => {
  return (dispatch) => {
    dispatch({ type: 'START_FETCHING_LIBRARY' })

    retrieveLibrary(userId)
    .then(response => response.json())
    .then(library => {
      if(library.errors) {
        throw library.errors
      } else {
        dispatch({ type: 'FETCH_LIBRARY', payload: { library: library } })
      }
    })
    .catch(err => {
      dispatch(setAlert('error', err))
      dispatch({type: 'ERROR_FETCHING_LIBRARY' })
      dispatch({type: 'CLEAR_LIBRARY_STATE'})
    })
  }
}

export const downloadSnip = (title, audio, audioLength, snipStartTime, snipStopTime) => {
  let fileName

  return (dispatch) => {
    dispatch({ type: 'START_DOWNLOADING_SNIP' })

    let titleStart = Math.round(snipStartTime)
    let titleEnd = Math.round(snipStopTime)
    let length = snipStopTime - snipStartTime

    fetch(`${snipprApiUrl}/snippets/download`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        title: `${title} (${titleStart}-${titleEnd} sec)`,
        audio: audio,
        audio_length_sec: length,
        start_time: snipStartTime,
        stop_time: snipStopTime
      })
    })
    .then(response => {
      if (response.ok) {
        let cd = response.headers.get('content-disposition')
        fileName = contentDisposition.parse(cd).parameters.filename
        return response.blob()
      } else {
        return response.json()
      }
    })
    .then(snip => {
      if(snip.errors) {
        dispatch(setAlert('error', snip.errors))
      } else {
        let url = window.URL.createObjectURL(snip)
        let a = document.createElement('a')
        a.href = url
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        a.remove()
      }
      dispatch({ type: 'STOP_DOWNLOADING_SNIP' })
    })
  }
}

export const removeFromLibrary = (id, userId) => {
  return (dispatch) => {
    dispatch({ type: 'REMOVE_FROM_LIBRARY' })

    fetch(`${snipprApiUrl}/library/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      method: 'DELETE',
      body: JSON.stringify({
        id: id,
        user_id: userId
      })
    })
    .then(response => response.json())
    .then(library => {
      if(library.errors) {
        dispatch(setAlert('error', library.errors))
      } else {
        dispatch({ type: 'FETCH_LIBRARY', payload: { library: library } })
      }
    })
  }
}

export const findSnipFromLibrary = (userId, rawSrc, snipStartTime, snipStopTime) => {
  return (dispatch) => {
    retrieveLibrary(userId)
    .then(response => response.json())
    .then(library => {
      let snippet = library.find(element => {
        return element.audio.raw_src === rawSrc && element.audio.start_time === snipStartTime && element.audio.stop_time === snipStopTime
      })
      dispatch(populateSnipUuid(snippet.audio.uuid))
      dispatch({type: 'SHOW_SNIP'})
    })
  }
}
