import { setAlert } from './Shared'
import { injectAudioElementIntoLibrary, findSnipFromLibrary } from './Library'
const snipprApiUrl = `${process.env.REACT_APP_SNIPPR_API_URL}`
const proxyUrl = `${process.env.REACT_APP_CORS_PROXY_URL}`

export const discardSnip = () => {
  return (dispatch) => {
    dispatch({type: 'DISCARD_SNIP'})
  }
}

export const pause = () => {
  return (dispatch) => {
    dispatch({type: 'PAUSE'})
    if (document.getElementsByTagName('audio')[0]) {
      document.getElementsByTagName('audio')[0].pause()
    }
  }
}

export const play = () => {
  return (dispatch) => {
    dispatch({type: 'PLAY'})
    document.getElementsByTagName('audio')[0].play()
    .catch(error => {
      dispatch({type: 'PAUSE'})
      console.error(error)
      /* NotAllowedErrors are due to user settings in the browser */
      /* Audio still loads fine but doesn't autoplay, so do not treat it as an error */
      if (error.name !== 'NotAllowedError') {
        dispatch(setAlert('error', 'Error playing audio'))
        dispatch({type: 'CLEAR_AUDIO_STATE'})
      }
    })
  }
}

export const setAndPlayAudio = ({audioId, audioUrl, audioLength, title, description, audioType, startTime, stopTime, podcastName, podcastId, ownerId, uuid, image, originalEpisodeName, shouldPlayOnLoad=true} = {}) => {
  return (dispatch) => {
    dispatch({type: 'PLAY_BUTTON_CLICKED', payload: {
      audioId: audioId
    }})
    fetch(audioUrl)
    .then(resp => {
      if (!resp.ok) {
        throw resp
      }
      dispatch({type: 'SHOW_AUDIO_CONTAINER'})
      dispatch({type: 'SET_AUDIO', payload: {
        audioId: audioId,
        audioUrl: audioUrl,
        audioLength: audioLength,
        title: title,
        description: description,
        audioType: audioType,
        startTime: startTime,
        stopTime: stopTime,
        podcastName: podcastName,
        podcastId: podcastId,
        ownerId: ownerId,
        uuid: uuid,
        image: image,
        originalEpisodeName: originalEpisodeName,
        shouldPlayOnLoad: shouldPlayOnLoad
      }})
      dispatch({type: 'POPULATE_SNIP_UUID', payload: {
        uuid: uuid
      }})
    })
    .catch(err => {
      console.error(err)
      // try using the proxy in case of redirects
      let proxyAudioUrl = `${proxyUrl}/${audioUrl}`
      fetch(proxyAudioUrl)
      .then(resp => {
        if (!resp.ok) {
          throw resp
        }
        dispatch({type: 'SHOW_AUDIO_CONTAINER'})
        dispatch({type: 'SET_AUDIO', payload: {
          audioId: audioId,
          audioUrl: proxyAudioUrl,
          audioLength: audioLength,
          title: title,
          description: description,
          audioType: audioType,
          startTime: startTime,
          stopTime: stopTime,
          podcastName: podcastName,
          podcastId: podcastId,
          ownerId: ownerId,
          uuid: uuid,
          image: image,
          originalEpisodeName: originalEpisodeName,
          shouldPlayOnLoad: shouldPlayOnLoad
        }})
        dispatch({type: 'POPULATE_SNIP_UUID', payload: {
          uuid: uuid
        }})
      })
      .catch(err => {
        dispatch(setAlert('error', 'Snippet or episode no longer available'))
        dispatch({type: 'ERROR_FETCHING_AUDIO' })
        dispatch({type: 'CLEAR_AUDIO_STATE'})
      })
    })
  }
}

export const setSnipStartTime = (startTime) => {
  return (dispatch) => {
    dispatch({type: 'SET_SNIP_START_TIME', payload: {
      snipStartTime: startTime
    }})
    dispatch({type: 'CLEAR_SNIP_UUID'})
  }
}

export const setSnipStopTime = (stopTime) => {
  return (dispatch) => {
    dispatch({type: 'SET_SNIP_STOP_TIME', payload: {
      snipStopTime: stopTime
    }})
    dispatch({type: 'CLEAR_SNIP_UUID'})
  }
}

export const startSnipping = () => {
  return (dispatch) => {
    dispatch({type: 'START_SNIPPING'})
  }
}

export const stopSnipping = () => {
  return (dispatch) => {
    dispatch({type: 'STOP_SNIPPING'})
  }
}

export const expand = () => {
  return (dispatch) => {
    dispatch({type: 'EXPAND'})
  }
}

export const collapse = () => {
  return (dispatch) => {
    dispatch({type: 'COLLAPSE'})
    dispatch({type: 'DISCARD_SNIP'})
  }
}

export const updateAudioDuration = (audioLength) => {
  return (dispatch) => {
    dispatch({type: 'UPDATE_AUDIO_DURATION', payload: {
      audioLength: audioLength
    }})
  }
}

export const startLoading = () => {
  return (dispatch) => {
    dispatch({type: 'START_LOADING'})
  }
}

export const stopLoading = () => {
  return (dispatch) => {
    dispatch({type: 'STOP_LOADING'})
  }
}

export const createSnip = (userId, title, audio, audioLength, podcastName, podcastId, rawSrc, snipStartTime, snipStopTime, image, originalEpisodeName) => {
  let titleStart = Math.round(snipStartTime)
  let titleEnd = Math.round(snipStopTime)
  let length = snipStopTime - snipStartTime
  let newAudio = `${rawSrc}#t=${snipStartTime},${snipStopTime}`
  return fetch(`${snipprApiUrl}/snippets`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      user_id: userId,
      title: `${title} (${titleStart}-${titleEnd} sec)`,
      audio: newAudio,
      audio_length_sec: length,
      podcast_name: podcastName,
      podcast_id: podcastId,
      original_episode_name: title,
      raw_src: rawSrc,
      start_time: snipStartTime,
      stop_time: snipStopTime,
      image: image,
      originalEpisodeName: originalEpisodeName
    })
  })
}

export const populateSnipUuid = (id) => {
  return (dispatch) => {
    dispatch({type: 'POPULATE_SNIP_UUID', payload: {
      uuid: id
    }})
  }
}

export const shareSnip = (...args) => {
  return (dispatch) => {
    dispatch({type: 'CREATING_SNIP'})
    return (createSnip(...args))
    .then(resp => resp.json())
    .then(snip => {
      if(snip.errors) {
        throw snip.errors
      } else {
        let uuid = snip.user_id ? snip.audio.uuid : snip.uuid
        dispatch({type: 'SNIP_CREATED' })
        dispatch(populateSnipUuid(uuid))
        dispatch({type: 'SHOW_SNIP'})
        if (snip.user_id) {
          dispatch(injectAudioElementIntoLibrary(snip))
        }
      }
    })
    .catch(err => {
      if (err[0] === 'User already has snippet in library') {
        let userId = args[0]
        let rawSrc = args[6]
        let snipStartTime = args[7]
        let snipStopTime = args[8]
        dispatch({type: 'RETRIEVING_SNIP_FROM_LIBRARY'})
        dispatch(findSnipFromLibrary(userId, rawSrc, snipStartTime, snipStopTime))
      } else {
        dispatch(setAlert('error', err))
        dispatch({type: 'ERROR_CREATING_SNIP' })
      }
    })
  }
}

export const fetchSnip = (id) => {
  let url = `${snipprApiUrl}/snippets/${id}`
  return fetch(url)
}

/* THIS IS HOW YOU MAKE ACTION CREATORS SYNCHRONOUS */
/* ANYTHING YOU NEED TO CALL, YOU NEED TO CALL WITH DISPATCH */
/* TRY CLEANING UP THE REST OF THE CODE NOW THAT YOU KNOW HOW TO DO IT */
export const showSnip = (id) => {
  return (dispatch) => {
    dispatch({type: 'START_FETCHING_SNIP'})
    fetchSnip(id)
    .then(resp => resp.json())
    .then(snip => {
      if(snip.errors) {
        throw snip.errors
      } else {
        dispatch({ type: 'SNIP_FETCHED' })
        dispatch(setAndPlayAudio(
          {
            audioId: snip.id,
            audioUrl: snip.audio,
            audioLength: snip.audio_length_sec,
            title: snip.title,
            description: snip.description,
            audioType: 'snippet',
            startTime: snip.start_time,
            stopTime: snip.stop_time,
            podcastName: snip.podcast_name,
            podcastId: snip.podcast_id,
            ownerId: snip.user_id,
            uuid: snip.uuid,
            image: snip.image,
            originalEpisodeName: snip.original_episode_name
          }
        ))
      }
    })
    .catch(err => {
      dispatch(setAlert('error', err))
      dispatch({type: 'ERROR_FETCHING_AUDIO' })
      dispatch({type: 'CLEAR_AUDIO_STATE'})
    })
  }
}

export const updateSnip = (id, title, description) => {
  return (dispatch) => {
    dispatch({type: 'START_UPDATING_SNIP'})
    fetch(`${snipprApiUrl}/snippets/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method: 'PUT',
    body: JSON.stringify({
      title: title,
      description: description
    })})
    .then(resp => resp.json())
    .then(snip => {
      if(snip.errors) {
        dispatch({type: 'ERROR_UPDATING_SNIP' })
        dispatch(setAlert('error', snip.errors))
      } else {
        dispatch({ type: 'SNIP_UPDATED' })
        dispatch(setAlert('success', 'Snippet updated successfully'))
        dispatch(showSnip(snip.uuid))
      }
    })
  }
}

export const updatePlayedThrough = () => {
  return (dispatch) => {
    dispatch({type: 'UPDATE_PLAYED_THROUGH'})
  }
}

export const updateShouldPlayOnLoad = () => {
  return (dispatch) => {
    dispatch({type: 'UPDATE_SHOULD_PLAY_ON_LOAD'})
  }
}
