import React, { Component } from 'react'
import LibraryElement from 'components/library/LibraryElement'
import { setAndPlayAudio, play, pause, discardSnip } from 'actions/Audio'
import { removeFromLibrary } from 'actions/Library'
import { connect } from 'react-redux'

class LibraryElementContainer extends Component {

  render() {
    return (
      <LibraryElement
        title={this.props.title}
        description={this.props.description}
        airDate={this.props.airDate}
        audio={this.props.audio}
        audioLength={this.props.audioLength}
        id={this.props.id}
        audioType={this.props.audioType}
        startTime={this.props.startTime}
        stopTime={this.props.stopTime}
        originalEpisodeName={this.props.originalEpisodeName}
        podcastName={this.props.podcastName}
        podcastId={this.props.podcastId}
        userId={this.props.userId}
        removeFromLibrary={this.props.removeFromLibrary}
        setAndPlayAudio={this.props.setAndPlayAudio}
        currentAudioId={this.props.currentAudioId}
        playing={this.props.playing}
        play={this.props.play}
        pause={this.props.pause}
        discardSnip={this.props.discardSnip}
        uuid={this.props.uuid}
        image={this.props.image}
        url={`${window.location.protocol}//${window.location.host}/snippets/${this.props.uuid}`}
        showAudioLoadingSpinner={this.props.showAudioLoadingSpinner}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    currentAudioId: state.currentAudio.audioId,
    playing: state.currentAudio.playing,
    showAudioLoadingSpinner: state.currentAudio.showAudioLoadingSpinner
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeFromLibrary: (audioId, userId) => dispatch(removeFromLibrary(audioId, userId)),
    setAndPlayAudio: (...args) => dispatch(setAndPlayAudio(...args)),
    play: () => dispatch(play()),
    pause: () => dispatch(pause()),
    discardSnip: () => dispatch(discardSnip())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LibraryElementContainer)
