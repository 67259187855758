import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share'

export default class SocialShareButtons extends Component {
  handleClick = () => {
    let body = encodeURIComponent(`
      Check out this snippet from ${this.props.podcastName}, courtesy of Snippr!
      ${this.props.shareUrl}
    `)

    let subject = encodeURIComponent(`Snippet from ${this.props.podcastName}`)
    window.open(`mailto:?subject=${subject}&body=${body}`)
  }

  render() {
    const shareUrl = this.props.shareUrl
    const title = `Check out this snippet from ${this.props.podcastName}, courtesy of Snippr!`

    return (
      <Container
        className="text-center my-4"
      >
        <div className="mb-3 fw-light fst-italic">
        Share on social media:
        </div>
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="mx-1"
        >
          <FacebookIcon size={40} round />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="mx-1"
        >
          <TwitterIcon size={40} round />
        </TwitterShareButton>
        <LinkedinShareButton
          url={shareUrl}
          className="mx-1"
        >
          <LinkedinIcon size={40} round />
        </LinkedinShareButton>
        <EmailShareButton
          className="mx-1"
          onClick={this.handleClick}
          target="_blank"
        >
          <EmailIcon size={40} round />
        </EmailShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          className="mx-1"
        >
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>
      </Container>
    )
  }
}
