import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'

export default class PasswordValidator extends Component {

  renderValidations = () => {
    return (
      this.props.validations.map((validation, idx) => {
        let message = validation.message.replace('should', 'must').replace('The string', 'Password').replace('symbol', 'special character')
        return (
          <dd key={idx} className='validation-failed'>
            {message}
          </dd>
        )
      })
    )
  }

  renderSuccess = () => {
    return (
      <dd className='validation-succeeded'>
        Password is valid
      </dd>
    )
  }

  render() {
    if (this.props.passwordChecked) {
      return (
        <Container className="px-5">
          <dl>
            {this.props.passwordIsValid ? this.renderSuccess() : this.renderValidations()}
          </dl>
        </Container>
      )
    } else {
      return null
    }
  }
}
