export default function appStatusReducer(state = {
  showStatus: false,
  statusType: 'NOT_SHARING'
}, action) {
  switch (action.type) {
    case 'LOADING_USER':
      return {
        ...state,
        status: 'LOADING_USER',
        showStatus: true,
        message: 'Logging you in...'
      }

    case 'ERROR_LOADING_USER':
      return {
        ...state,
        status: null,
        showStatus: false,
        message: null
      }

    case 'CREATING_USER':
      return {
        ...state,
        status: 'CREATING_USER',
        showStatus: true,
        message: 'Creating your profile...'
      }

    case 'ERROR_CREATING_USER':
      return {
        ...state,
        status: null,
        showStatus: false,
        message: null
      }

    case 'USER_LOADED':
      return {
        ...state,
        status: 'USER_LOADED',
        showStatus: false,
        message: null
      }

    case 'USER_CREATED':
      return {
        ...state,
        status: 'USER_CREATED',
        showStatus: false,
        message: null
      }

    case 'CREATING_SNIP':
      return {
        ...state,
        status: 'CREATING_SNIP',
        showStatus: true,
        message: 'Generating snippet...'
      }

    case 'RETRIEVING_SNIP_FROM_LIBRARY':
      return {
        ...state,
        status: 'RETRIEVING_SNIP_FROM_LIBRARY',
        showStatus: true,
        message: 'Retrieving snippet from library...'
      }

    case 'ERROR_CREATING_SNIP':
      return {
        ...state,
        status: 'ERROR_CREATING_SNIP',
        showStatus: false,
        message: null
      }

    case 'SNIP_CREATED':
      return {
        ...state,
        status: 'SNIP_CREATED',
        showStatus: false,
        message: null
      }

    case 'SHOW_SNIP':
      return {
        ...state,
        status: 'SHOW_SNIP',
        showStatus: true,
        statusType: 'SHARING'
      }

    case 'DISMISS_SNIP':
      return {
        ...state,
        status: 'DISMISS_SNIP',
        showStatus: false,
        statusType: 'NOT_SHARING'
      }

    case 'CLEAR_SNIP_UUID':
      return {
        ...state,
        uuid: null
      }

    case 'POPULATE_SNIP_UUID':
      return {
        ...state,
        uuid: action.payload.uuid
      }

    default:
      return state
  }
}
