import React, { Component } from 'react'
import ForgotPasswordModal from 'components/user/ForgotPasswordModal'
import { connect } from 'react-redux'
import { showForgotPasswordModal, hideForgotPasswordModal, sendResetPasswordCode, verifyResetPasswordCode, updatePassword, clearPasswordResetData, login } from 'actions/User'


class ForgotPasswordModalContainer extends Component {
  render() {
    return (
      <ForgotPasswordModal
        show={this.props.show}
        showForgotPasswordModal={this.props.showForgotPasswordModal}
        hideForgotPasswordModal={this.props.hideForgotPasswordModal}
        sendResetPasswordCode={this.props.sendResetPasswordCode}
        verifyResetPasswordCode={this.props.verifyResetPasswordCode}
        updatePassword={this.props.updatePassword}
        status={this.props.status}
        email={this.props.email}
        password={this.props.password}
        passwordResetCode={this.props.passwordResetCode}
        login={this.props.login}
        clearPasswordResetData={this.props.clearPasswordResetData}
        disableUpdatePasswordButton={this.props.disableUpdatePasswordButton}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    show: state.user.showForgotPasswordModal,
    status: state.user.status,
    email: state.user.email,
    passwordResetCode: state.user.passwordResetCode,
    password: state.user.password,
    disableUpdatePasswordButton: (state.user.passwordChecked && !state.user.passwordIsValid)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideForgotPasswordModal: () => dispatch(hideForgotPasswordModal()),
    showForgotPasswordModal: () => dispatch(showForgotPasswordModal()),
    sendResetPasswordCode: (email) => dispatch(sendResetPasswordCode(email)),
    verifyResetPasswordCode: (code, email) => dispatch(verifyResetPasswordCode(code, email)),
    updatePassword: (password, passwordConfirmation, code, email) => dispatch(updatePassword(password, passwordConfirmation, code, email)),
    login: (userObj) => dispatch(login(userObj)),
    clearPasswordResetData: () => dispatch(clearPasswordResetData())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModalContainer)
