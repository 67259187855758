import React, { Component } from 'react'
import { GoogleLogin } from 'react-google-login'
import { Icon } from '@iconify/react'
import { Button } from 'react-bootstrap'

export default class GoogleLoginButton extends Component {

  handleLogin = (response) => {
    this.props.loginWith("google", response)
  }

  render() {
    return (
      <GoogleLogin
        clientId={this.props.appId}
        buttonText="Log in with Google"
        onSuccess={this.handleLogin}
        onFailure={this.handleLogin}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <Button
            variant="primary"
            className="mb-2 submit-button google-login-button"
            onClick={renderProps.onClick}
          >
            <Icon icon="flat-color-icons:google" height="24px"/>
            <span className="google-login-text">Log in with Google</span>
          </Button>
        )}
      />
    )
  }
}
