import React, { Component } from 'react'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export default class SharedSnippet extends Component {

  state = {
    title: this.props.title,
    description: this.props.description,
    loading: true
  }

  static getDerivedStateFromProps(props, state) {
    if (state.title || state.title === '') {
      return {
        title: state.title,
        description: state.description
      }
    } else {
      return {
        title: props.title,
        description: props.description
      }
    }
  }

  componentDidUpdate() {
    if (this.state.loading && this.props.audio != null) {
      this.setState({
        ...this.state,
        loading: false
      })
    }
  }

  handleChange = event => {
    this.setState({
      ...this.state,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.props.updateSnip(this.props.uuid, this.state.title, this.state.description)
  }

  renderTitle = () => {
    let title
    if (this.state.loading) {
      title = <Skeleton />
    } else if (this.props.isOwner) {
      title =
      <FloatingLabel
        controlId="floatingInput"
        label="Snippet title"
      >
        <Form.Control
          type="text"
          name="title"
          placeholder="Title"
          onChange={this.handleChange}
          value={this.state.title}
        />
      </FloatingLabel>
    } else {
      title =
        <Card.Title>
          {this.state.title}
        </Card.Title>
    }
    return title
  }

  renderDescription = () => {
    let description
    if (this.state.loading) {
      description = <Skeleton />
    } else if (this.props.isOwner) {
      description =
      <FloatingLabel
        controlId="floatingInput"
        label="Description"
      >
        <Form.Control
          type="text"
          name="description"
          placeholder="Description"
          as="textarea"
          style={{ height: '100px' }}
          onChange={this.handleChange}
          value={this.state.description}
        />
      </FloatingLabel>
    } else {
      description =
      <Card.Text>
        {this.state.description}
      </Card.Text>
    }
    return description
  }

  renderSubmit = () => {
    let button
    if (this.state.loading) {
      button = <Skeleton />
    } else if (this.props.isOwner) {
      button =
        <Button
          variant="primary"
          type="submit"
          className="mt-3 submit-button"
          onClick={(e) => this.handleSubmit(e)}
        >
          Update snippet
        </Button>
    } else {
      button = null
    }
    return button
  }

  renderSnipSource = () => {
    if (this.state.loading) {
      return <Skeleton/>
    } else {
      return (
        <>
          Snipped from {this.props.podcastName} - {this.props.originalEpisodeName}
        </>
      )
    }
  }

  renderSnipStartTime = () => {
    if (this.state.loading) {
      return <Skeleton/>
    } else {
      return (
        <>
          Start time: {moment.duration(this.props.startTime, 'seconds').format('hh:mm:ss')}
        </>
      )
    }
  }

  renderSnipStopTime = () => {
    if (this.state.loading) {
      return <Skeleton/>
    } else {
      return (
        <>
          Stop time: {moment.duration(this.props.stopTime, 'seconds').format('hh:mm:ss')}
        </>
      )
    }
  }

  renderThumbnail = () => {
    if (this.state.loading) {
      return <Skeleton className="thumbnail-skeleton" enableAnimation={false}/>
    } else {
      return (
        <>
          <Image src={this.props.image} thumbnail/>
        </>
      )
    }
  }

  renderPodcastName = () => {
    if (this.state.loading) {
      return <Skeleton />
    } else {
      return (
        <>
          {this.props.podcastName}
        </>
      )
    }
  }

  render() {
    return (
      <Container fluid className="mt-4">
        <Row className="align-items-center">
          <Col xs="12" sm="4" className="text-center mb-4">
            {this.renderThumbnail()}
          </Col>
          <Col sm="8" className="mb-4">
            <Card>
              <Card.Header as="h5">
                {this.renderPodcastName()}
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="update-title" className="mb-3">
                      {this.renderTitle()}
                    </Form.Group>
                    <Form.Group controlId="update-description">
                      {this.renderDescription()}
                    </Form.Group>
                      {this.renderSubmit()}
                  </Form>
                </Card.Text>
                <Form.Text muted>
                  <Card.Text className="mb-1">
                    {this.renderSnipSource()}
                  </Card.Text>
                  <Card.Text className="mb-1">
                    {this.renderSnipStartTime()}
                  </Card.Text>
                  <Card.Text className="mb-1">
                    {this.renderSnipStopTime()}
                  </Card.Text>
                </Form.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}
