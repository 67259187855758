import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'

export default class EmailValidator extends Component {

  renderValidations = () => {
    return (
      this.props.validations.map((validation, idx) => {
        return (
          <dd key={idx} className='validation-failed'>
            {validation}
          </dd>
        )
      })
    )
  }

  renderSuccess = () => {
    return (
      <dd className='validation-succeeded'>
        Email is valid
      </dd>
    )
  }

  render() {
    if (this.props.emailChecked) {
      return (
        <Container className="px-5">
          <dl>
            {this.props.emailIsValid ? this.renderSuccess() : this.renderValidations()}
          </dl>
        </Container>
      )
    } else {
      return null
    }
  }
}
