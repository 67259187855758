import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import { NavLink } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import PasswordValidatorContainer from 'containers/user/PasswordValidatorContainer'


export default class ForgotPasswordModal extends Component {

  state = {
    email: '',
    resetPasswordCode: '',
    password: '',
    passwordConfirmation: ''
  }

  componentDidUpdate() {
    if(this.props.status === 'PASSWORD_UPDATED_SUCCESSFULLY') {
      this.props.login({ email: this.props.email, password: this.props.password })
      this.props.clearPasswordResetData()
    }
  }

  handleChange = e => {
    e.stopPropagation()
    this.setState({
      ...this.state,
      [e.currentTarget.name]: e.currentTarget.value
    })
  }

  handleSendCode = e => {
    e.stopPropagation()
    e.preventDefault()
    if (this.state.email !== '') {
      this.props.sendResetPasswordCode(this.state.email)
      this.setState({
        ...this.state,
        email: ''
      })
    }
  }

  handleSubmitCode = e => {
    e.stopPropagation()
    e.preventDefault()
    if (this.state.resetPasswordCode !== '') {
      this.props.verifyResetPasswordCode(this.state.resetPasswordCode, this.props.email)
      this.setState({
        ...this.state,
        resetPasswordCode: ''
      })
    }
  }

  handleUpdatePassword = e => {
    e.stopPropagation()
    e.preventDefault()
    if (this.state.password !== '' && this.state.passwordConfirmation !== '') {
      this.props.updatePassword(this.state.password, this.state.passwordConfirmation, this.props.passwordResetCode, this.props.email)
      this.setState({
        ...this.state,
        password: '',
        passwordConfirmation: ''
      })
    }
  }

  handleClose = () => {
    this.props.hideForgotPasswordModal()
  }

  renderSignupLink = () => {
    return (
       <NavLink to="/signup" onClick={this.handleClose}>signing up</NavLink>
    )
  }

  renderSendEmailForm = () => {
    return (
      <Form onSubmit={this.handleSendCode}>
        <Form.Group controlId="forgot-password" className="my-3">
          <FormControl
            type="email"
            name="email"
            placeholder="Email address"
            onChange={this.handleChange}
            value={this.state.email}
            autoComplete="on"
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="submit-button">
          Send code
        </Button>
      </Form>
    )
  }

  renderSubmitCodeForm = () => {
    return (
      <Form onSubmit={this.handleSubmitCode}>
        <Form.Group controlId="submit-code" className="my-3">
          <FormControl
            type="text"
            name="resetPasswordCode"
            placeholder="Code"
            onChange={this.handleChange}
            value={this.state.resetPasswordCode}
            autoComplete="on"
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="submit-button">
          Submit
        </Button>
      </Form>
    )
  }

  renderUpdatePasswordForm = () => {
    return (
      <Form onSubmit={this.handleUpdatePassword}>
        <Form.Group controlId="update-password" className="my-3">
          <FormControl
            type="password"
            name="password"
            placeholder="Password"
            onChange={this.handleChange}
            value={this.state.password}
            autoComplete="on"
          />
        </Form.Group>
        <Form.Group controlId="update-password-confirmation" className="my-3">
          <FormControl
            type="password"
            name="passwordConfirmation"
            placeholder="Confirm password"
            onChange={this.handleChange}
            value={this.state.passwordConfirmation}
            autoComplete="on"
          />
        </Form.Group>
        <PasswordValidatorContainer
          password={this.state.password}
          passwordConfirmation={this.state.passwordConfirmation}
        />
        <Button
          variant="primary"
          type="submit"
          className="submit-button"
          disabled={this.props.disableUpdatePasswordButton}
        >
          Update password
        </Button>
      </Form>
    )
  }

  renderBody = () => {
    if (this.props.status === 'USER_NOT_FOUND') {
      return (
        <div>
          Hmm, we couldn't find a user with that email address. Try {this.renderSignupLink()} instead.
        </div>
      )
    } else if (this.props.status === 'SENDING_CODE') {
      return (
        <div>
          Sending code...
        </div>
      )
    } else if (this.props.status === 'CODE_SENT') {
      return (
        <div>
          We've sent you a code. Check your inbox and enter the code here to reset your password:
          {this.renderSubmitCodeForm()}
        </div>
      )
    } else if (this.props.status === 'CODE_INCORRECT') {
      return (
        <div>
          That code was incorrect. Try again:
          {this.renderSubmitCodeForm()}
        </div>
      )
    } else if (this.props.status === 'CODE_EXPIRED') {
      return (
        <div>
          That code has expired. Send a new code to your email address:
          {this.renderSendEmailForm()}
        </div>
      )
    } else if (this.props.status === 'VERIFYING_CODE') {
      return (
        <div>
          Verifying code...
        </div>
      )
    } else if (this.props.status === 'CODE_VERIFIED') {
      return (
        <div>
          Great! Now update your password before continuing:
          {this.renderUpdatePasswordForm()}
        </div>
      )
    } else if (this.props.status === 'UPDATING_PASSWORD') {
      return (
        <div>
          Updating password...
        </div>
      )
    } else if (this.props.status === 'PASSWORD_DOES_NOT_MATCH_PASSWORD_CONFIRMATION') {
      return (
        <div>
          Password does not match password confirmation. Try again:
          {this.renderUpdatePasswordForm()}
        </div>
      )
    } else if (this.props.status === 'PASSWORD_UPDATED_SUCCESSFULLY') {
      return (
        <div>
          Password updated successfully! Logging you in...
        </div>
      )
    } else if (this.props.status === 'PASSWORD_UPDATED_FAILED') {
      return (
        <div>
          Hmm, something went wrong. Try again:
          {this.renderSendEmailForm()}
        </div>
      )
    } else {
      return (
        <div>
          Enter the email address associated with your account. We'll send you a code to reset your password.
          {this.renderSendEmailForm()}
        </div>
      )
    }
  }

  render() {
    if (this.props.status === 'PASSWORD_UPDATED_SUCCESSFULLY') {
      return <Redirect to='/' />
    } else {
      return (
        <Modal
          show={this.props.show}
          onHide={this.handleClose}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Forgot password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderBody()}
          </Modal.Body>
        </Modal>
      )
    }
  }
}
