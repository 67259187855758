import React, { Component } from 'react'
import Bar from 'components/audio/Bar'
import PlayPause from 'components/audio/PlayPause'
import SnippingContainer from './SnippingContainer'
import {
  play,
  pause,
  expand,
  collapse,
  updateAudioDuration,
  setSnipStartTime,
  setSnipStopTime,
  startLoading,
  stopLoading,
  updatePlayedThrough,
  updateShouldPlayOnLoad
} from 'actions/Audio'
import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import collapseButtonImg from '../../assets/images/icons/collapse-arrow.svg'
import expandButtonImg from '../../assets/images/icons/expand-arrow.svg'


class AudioContainer extends Component {

  constructor(props) {
    super(props)
    this.audioRef = React.createRef()
    let currentTime = this.props.audioType === 'snippet' ? this.props.startTime : 0

    this.state = {
      currentTime: currentTime,
      firstPlay: true,
      startSnipTime: 0,
      playing: false,
      playAfterDrag: false,
      currentlyPlayingAudioId: null
    }

    document.addEventListener('keydown', e => {
      if (this.audioRef.current) {
        if (e.keyCode === 37) {
          this.handleRewind(1)
        } else if (e.keyCode === 39) {
          this.handleFastForward(1)
        }
      }
    })
  }

  togglePlay = () => {
    if (this.props.playing) {
      this.props.pause()
    } else {
      this.props.play()
    }
  }

  handleTimeUpdate = () => {
    let currentTime
    if (this.props.audioType === 'snippet') {
      currentTime = this.audioRef.current.currentTime >= this.props.stopTime ? this.props.stopTime : this.audioRef.current.currentTime
    } else {
      currentTime = this.audioRef.current.currentTime >= this.props.audioLength ? this.props.audioLength : this.audioRef.current.currentTime
    }

    this.setState({
      ...this.state,
      currentTime: currentTime
    }, () => {
      if (this.props.audioType !== 'snippet' && this.state.currentTime === this.props.audioLength) {
        this.audioRef.current.currentTime = 0
        this.props.pause()
        this.props.updatePlayedThrough()
      } else if (this.props.audioType === 'snippet' && this.state.currentTime === this.props.stopTime) {
        this.audioRef.current.currentTime = this.props.startTime
        this.props.pause()
        this.props.updatePlayedThrough()
      }
    })
  }

  handleTimeDrag = (offsetRatio) => {
    let currentTime
    if (this.props.audioType === 'snippet') {
      currentTime = this.props.startTime + (this.props.audioLength*offsetRatio)
    } else {
      currentTime = this.props.audioLength*offsetRatio
    }

    this.setState({
      ...this.state,
      currentTime: currentTime
    }, () => {
      this.audioRef.current.currentTime = this.state.currentTime
    })
  }

  handleFastForward = (seconds = 10) => {
    let currentTime
    let maxTime = this.props.audioType === 'snippet' ? this.props.stopTime : this.props.audioLength

    if (this.state.currentTime + seconds >= maxTime) {
      currentTime = maxTime
    } else {
      currentTime = this.state.currentTime + seconds
    }

    this.setState({
      ...this.state,
      currentTime: currentTime
    }, () => {
      this.audioRef.current.currentTime = this.state.currentTime
    })
  }

  handleRewind = (seconds = 10) => {
    let currentTime
    let minTime = this.props.audioType === 'snippet' ? this.props.startTime : 0

    if (this.state.currentTime - seconds <= minTime) {
      currentTime = minTime
    } else {
      currentTime = this.state.currentTime - seconds
    }

    this.setState({
      ...this.state,
      currentTime: currentTime
    }, () => {
      this.audioRef.current.currentTime = this.state.currentTime
    })
  }

  renderSnippingContainer = () => {
    if(this.props.expanded) {
      return (
        <SnippingContainer
          currentTime={this.state.currentTime}
          audioType={this.props.audioType}
          handleFastForward={() => this.handleFastForward()}
          handleRewind={() => this.handleRewind()}
        />
      )
    }
  }

  toggleExpandCollapse = () => {
    if (this.props.expanded) {
      this.props.collapse()
    } else {
      this.props.expand()
    }
  }

  renderExpandCollapseButton = () => {
    const expandCollapseImg = this.props.expanded ? collapseButtonImg : expandButtonImg

    return (
      <div>
        <input
          type="image"
          alt="expand collapse button"
          className="expand-collapse-button"
          src={expandCollapseImg}
          onClick={this.toggleExpandCollapse}
        />
      </div>
    )
  }

  handleOnLoadedMetadata = () => {
    /* Update audio duration since the duration provided by the
    ListenNotes API isn't accurate */
    if (this.props.audioType !== 'snippet') {
      let audio = document.getElementById(this.props.audioId)
      this.props.updateAudioDuration(audio.duration)
    }

    /* Ideally this would be handled with the onCanPlay event
    but that event doesn't appear to fire on mobile for some reason */
    this.props.stopLoading()
    if (this.state.currentlyPlayingAudioId !== this.props.audioId) {
      if (this.props.shouldPlayOnLoad) {
        this.props.play()
      } else {
        this.setState({
          ...this.state,
          currentlyPlayingAudioId: this.props.audioId,
          currentTime: this.props.audioType === 'snippet' ? this.props.startTime : 0
        })
        this.props.updateShouldPlayOnLoad()
      }
    }
  }

  handleOnLoadStart = () => {
    this.props.startLoading()
  }

  handleOnProgress = () => {
    /* show buffering status...
    for (let i = 0; i < this.audioRef.current.buffered.length; i++) {
      console.log(this.audioRef.current.buffered.start(i))
      console.log(this.audioRef.current.buffered.end(i))
      this.setState({
        ...this.state,
        bufferedRanges:
      })
    }
    */
  }

  render() {
    let offsetRatio
    let timeFromEnd
    if (this.props.audioType === 'snippet') {
      offsetRatio = ((this.state.currentTime - this.props.startTime)/this.props.audioLength)*100
      timeFromEnd = (this.props.stopTime - this.state.currentTime) + this.props.startTime
    } else {
      offsetRatio = (this.state.currentTime/this.props.audioLength)*100
      timeFromEnd = this.props.audioLength - this.state.currentTime
    }

    if (this.props.showing) {
      return (
        <LoadingOverlay
          active={this.props.loading}
          spinner
        >
        <div
          id='current-audio-player-container'
        >
          <div id='current-audio-player'>
            <audio
              id={this.props.audioId}
              src={this.props.audioUrl}
              ref={this.audioRef}
              onTimeUpdate={this.handleTimeUpdate}
              onLoadedMetadata={this.handleOnLoadedMetadata}
              onLoadStart={this.handleOnLoadStart}
              onProgress={this.handleOnProgress}
            />
            <PlayPause
              togglePlay={this.togglePlay}
              playing={this.props.playing}
              currentAudioUrl={this.props.audioUrl}
              audioRef={this.audioRef}
              currentAudioId={this.props.audioId}
              audioId={this.props.id}
              stopSnipping={this.props.stopSnipping}
            />
            <Bar
              audioRef={this.audioRef}
              audioLength={this.props.audioLength}
              audioId={this.props.id}
              currentAudioId={this.props.audioId}
              timeFromEnd={timeFromEnd}
              timeFromStart={this.state.currentTime}
              offsetRatio={offsetRatio}
              handleTimeDrag={this.handleTimeDrag}
              snipping={this.props.snipping}
              snipStartTime={this.props.snipStartTime}
              snipStopTime={this.props.snipStopTime}
              setSnipStartTime={this.props.setSnipStartTime}
              setSnipStopTime={this.props.setSnipStopTime}
              pause={this.props.pause}
              play={this.props.play}
              playing={this.props.playing}
            />
            {this.renderExpandCollapseButton()}
          </div>
          {this.renderSnippingContainer()}
        </div>
      </LoadingOverlay>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => {
  return {
    audioId: state.currentAudio.audioId,
    audioUrl: state.currentAudio.audioUrl,
    snipping: state.currentAudio.snipping,
    playing: state.currentAudio.playing,
    showing: state.currentAudio.showing,
    expanded: state.currentAudio.expanded,
    snipStartTime: state.currentAudio.snipStartTime,
    snipStopTime: state.currentAudio.snipStopTime,
    audioLength: state.currentAudio.audioLength,
    loading: state.currentAudio.loading,
    audioType: state.currentAudio.audioType,
    startTime: state.currentAudio.startTime,
    stopTime: state.currentAudio.stopTime,
    playedThrough: state.currentAudio.playedThrough,
    shouldPlayOnLoad: state.currentAudio.shouldPlayOnLoad
  }
}

const mapDispatchToProps = dispatch => {
  return {
    play: () => dispatch(play()),
    pause: () => dispatch(pause()),
    expand: () => dispatch(expand()),
    collapse: () => dispatch(collapse()),
    updateAudioDuration: (duration) => dispatch(updateAudioDuration(duration)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    setSnipStartTime: (startTime) => dispatch(setSnipStartTime(startTime)),
    setSnipStopTime: (stopTime) => dispatch(setSnipStopTime(stopTime)),
    updatePlayedThrough: () => dispatch(updatePlayedThrough()),
    updateShouldPlayOnLoad: () => dispatch(updateShouldPlayOnLoad())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioContainer)
