import React, { Component } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Icon } from '@iconify/react'
import { Button } from 'react-bootstrap'

export default class FacebookLoginButton extends Component {

  handleLogin = (response) => {
    this.props.loginWith("facebook", response)
  }

  render() {
    return (
      <FacebookLogin
        appId={this.props.appId}
        autoLoad={false}
        fields="name,email,picture"
        callback={this.handleLogin}
        disableMobileRedirect={true}
        render={renderProps => (
          <Button
            variant="primary"
            className="mb-2 submit-button facebook-login-button"
            onClick={renderProps.onClick}
          >
            <Icon icon="ion:logo-facebook" height="24px"/>
            <span className="facebook-login-text">Log in with Facebook</span>
          </Button>
        )}
      />
    )
  }
}
