import React, { Component } from 'react'
import SharedSnippet from 'components/audio/SharedSnippet'
import { Redirect } from 'react-router-dom'
import { play, showSnip, updateSnip } from 'actions/Audio'
import { connect } from 'react-redux'

class SharedSnippetContainer extends Component {

  componentDidMount() {
    const { match: { params } } = this.props
    this.props.showSnip(params.snippetId)
  }

  render() {
    if (this.props.error) {
      return (
        <Redirect to="/" />
      )
    } else {
      return (
        <SharedSnippet
          title={this.props.title}
          podcastName={this.props.podcastName}
          description={this.props.description}
          audio={this.props.audio}
          id={this.props.id}
          playing={this.props.playing}
          play={this.props.play}
          stopTime={this.props.stopTime}
          startTime={this.props.startTime}
          playedThrough={this.props.playedThrough}
          isOwner={this.props.isOwner}
          uuid={this.props.uuid}
          updateSnip={this.props.updateSnip}
          image={this.props.image}
          originalEpisodeName={this.props.originalEpisodeName}
          loading={this.props.loading}
        />
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    title: state.currentAudio.title,
    description: state.currentAudio.description,
    podcastName: state.currentAudio.podcastName,
    audio: state.currentAudio.audioUrl,
    id: state.currentAudio.audioId,
    playing: state.currentAudio.playing,
    startTime: state.currentAudio.startTime,
    stopTime: state.currentAudio.stopTime,
    playedThrough: state.currentAudio.playedThrough,
    isOwner: (state.session.authenticated && state.session.user.id === state.currentAudio.ownerId),
    uuid: state.currentAudio.uuid,
    image: state.currentAudio.image,
    originalEpisodeName: state.currentAudio.originalEpisodeName,
    error: (state.alerts.status === 'error' && (state.alerts.message[0] === 'Snippet does not exist' || state.alerts.message[0] === 'Snippet no longer available')),
    loading: state.currentAudio.loadingSnip
  }
}

const mapDispatchToProps = dispatch => {
  return {
    play: () => dispatch(play()),
    showSnip: (uuid) => dispatch(showSnip(uuid)),
    updateSnip: (uuid, title, description) => dispatch(updateSnip(uuid, title, description))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedSnippetContainer)
