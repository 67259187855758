export default function userReducer(state = {
  showForgotPasswordModal: false,
  status: null,
  email: null,
  passwordResetCode: null,
  password: null,
  emailIsValid: false,
  emailChecked: false,
  passwordIsValid: false,
  passwordChecked: false
}, action) {
  switch (action.type) {
    case 'SHOW_FORGOT_PASSWORD_MODAL':
      return {
        ...state,
        showForgotPasswordModal: true
      }

    case 'HIDE_FORGOT_PASSWORD_MODAL':
      return {
        ...state,
        showForgotPasswordModal: false
      }

    case 'RESET_PASSWORD_USER_NOT_FOUND':
      return {
        ...state,
        status: 'USER_NOT_FOUND'
      }

    case 'SENDING_RESET_PASSWORD_CODE':
      return {
        ...state,
        status: 'SENDING_CODE',
        email: action.payload.email
      }

    case 'RESET_PASSWORD_CODE_SENT':
      return {
        ...state,
        status: 'CODE_SENT'
      }

    case 'VERIFY_RESET_PASSWORD_CODE':
      return {
        ...state,
        status: 'VERIFYING_CODE',
        passwordResetCode: action.payload.passwordResetCode
      }

    case 'RESET_PASSWORD_CODE_VERIFIED':
      return {
        ...state,
        status: 'CODE_VERIFIED'
      }

    case 'RESET_PASSWORD_CODE_INCORRECT':
      return {
        ...state,
        status: 'CODE_INCORRECT'
      }

    case 'RESET_PASSWORD_CODE_EXPIRED':
      return {
        ...state,
        status: 'CODE_EXPIRED'
      }

    case 'UPDATE_PASSWORD':
      return {
        ...state,
        status: 'UPDATING_PASSWORD',
        password: action.payload.password
      }

    case 'PASSWORD_UPDATED_SUCCESSFULLY':
      return {
        ...state,
        status: 'PASSWORD_UPDATED_SUCCESSFULLY'
      }

    case 'PASSWORD_DOES_NOT_MATCH_PASSWORD_CONFIRMATION':
      return {
        ...state,
        status: 'PASSWORD_DOES_NOT_MATCH_PASSWORD_CONFIRMATION'
      }

    case 'PASSWORD_UPDATED_FAILED':
      return {
        ...state,
        status: 'PASSWORD_UPDATED_FAILED'
      }

    case 'CLEAR_PASSWORD_RESET_DATA':
      return {
        ...state,
        showForgotPasswordModal: false,
        status: null,
        email: null,
        passwordResetCode: null,
        password: null
      }

    case 'INVALIDATE_EMAIL':
      return {
        ...state,
        emailIsValid: false,
        emailChecked: true
      }

    case 'VALIDATE_EMAIL':
      return {
        ...state,
        emailIsValid: true,
        emailChecked: true
      }

    case 'INVALIDATE_PASSWORD':
      return {
        ...state,
        passwordIsValid: false,
        passwordChecked: true
      }

    case 'VALIDATE_PASSWORD':
      return {
        ...state,
        passwordIsValid: true,
        passwordChecked: true
      }

    default:
      return state
  }
}
