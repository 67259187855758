import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'
import OauthContainer from 'containers/user/OauthContainer'
import EmailValidatorContainer from 'containers/user/EmailValidatorContainer'
import PasswordValidatorContainer from 'containers/user/PasswordValidatorContainer'

export default class SignUpForm extends Component {

  state = {
    email: '',
    password: '',
    passwordConfirmation: ''
  }

  handleChange = event => {
    this.setState({
      ...this.state,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.props.signup(this.state)
    this.setState({
      email: '',
      password: '',
      passwordConfirmation: ''
    })
  }

  render() {
    return (
      <Container>
        <Form className="m-4" onSubmit={this.handleSubmit}>
          <Form.Group controlId="signup-email" className="mb-3">
            <Form.Control
              type="email"
              name="email"
              placeholder="Email address"
              onChange={this.handleChange}
              value={this.state.email}
              autoComplete="on"
            />
          </Form.Group>
          <EmailValidatorContainer
            email={this.state.email}
          />
          <Form.Group controlId="signup-password" className="mb-3">
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              onChange={this.handleChange}
              value={this.state.password}
              autoComplete="on"
            />
          </Form.Group>
          <Form.Group controlId="signup-password-confirmation" className="mb-3">
            <Form.Control
              type="password"
              name="passwordConfirmation"
              placeholder="Confirm password"
              onChange={this.handleChange}
              value={this.state.passwordConfirmation}
              autoComplete="on"
            />
          </Form.Group>
          <PasswordValidatorContainer
            password={this.state.password}
            passwordConfirmation={this.state.passwordConfirmation}
          />
          <Button
            variant="primary"
            type="submit"
            className="submit-button"
            disabled={this.props.disableSignUpButton}
          >
            Sign up
          </Button>
          <OauthContainer/>
        </Form>
      </Container>
    )
  }
}
