import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'

export default class Home extends Component {

  render() {
    return (
      <Container className="mt-5 mb-5">
        <header className="mb-4">
          <h2>Welcome to Snippr!</h2>
        </header>
        <main className='text-body'>
          <p className="mb-0">
            Snippr is a podcast listening web application that allows users to split podcast episodes into smaller audio clips, or "snippets", for sharing, downloading, or adding to their library. To get started, click <Link to="/search">search</Link> in the navigation bar and search for your favorite podcast. Here's a quick mini tutorial:
          </p>

          <p>
            <br/>
            <div>
              <iframe
                allow="autoplay"
                src="https://www.youtube.com/embed/gFjwcw5LQnY?autoplay=1"
                title="snippr-tutorial"
                id="snippr-tutorial"
              />
            </div>
          </p>

          <p>
            This project was inspired by my mother, Katie O'Toole, who is a professor of journalism at Penn State's College of Communications. She teaches a course in podcasting and expressed the need to send small clips of podcast episodes to her students in order to highlight the dos and don'ts of podcasting. Katie has a podcast herself, <Link to="podcasts/af679e331dad496f8c3ee0b93c42a618">Dead Centre</Link>, about the stories, people, and events that make up the rich history of Centre County, Pennsylvania. Check it out!
          </p>

          <p>
            Snippr is a React/Redux frontend web application with a Rails API backend. Snippr pulls from the <a href="https://www.listennotes.com/api/">ListenNotes API</a> to fetch data on publicly available podcasts and episodes.
          </p>
        </main>
        <footer className='text-secondary'>
          © 2022 Thomas Gray
        </footer>
      </Container>
    )
  }
}
