import React, { Component } from 'react'
import Episode from 'components/search/Episode'
import { setAndPlayAudio, play, pause, discardSnip } from 'actions/Audio'
import { connect } from 'react-redux'

class EpisodeContainer extends Component {

  createDescription = () => {
    return this.props.description ? this.props.description.replace(/(<([^>]+)>)/ig,"") : null
  }

  render() {
    return (
      <Episode
        title={this.props.title}
        description={this.createDescription()}
        airDate={this.props.airDate}
        audio={this.props.audio}
        audioLength={this.props.audioLength}
        id={this.props.id}
        audioType={this.props.audioType}
        podcastName={this.props.podcastName}
        podcastId={this.props.podcastId}
        setAndPlayAudio={this.props.setAndPlayAudio}
        currentAudioId={this.props.currentAudioId}
        playing={this.props.playing}
        play={this.props.play}
        pause={this.props.pause}
        discardSnip={this.props.discardSnip}
        image={this.props.image}
        showAudioLoadingSpinner={this.props.showAudioLoadingSpinner}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    currentAudioId: state.currentAudio.audioId,
    playing: state.currentAudio.playing,
    showAudioLoadingSpinner: state.currentAudio.showAudioLoadingSpinner
  }
}

const mapDispatchToProps = dispatch => {
  /* a bunch of this isn't being used; update here, in the reducer, and in the child components */
  return {
    setAndPlayAudio: (...args) => dispatch(setAndPlayAudio(...args)),
    play: () => dispatch(play()),
    pause: () => dispatch(pause()),
    discardSnip: () => dispatch(discardSnip())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeContainer)
