export default function init() {
  if (window.location.hostname === 'pod-snippr.herokuapp.com') {
    let url = window.location.href.replace('pod-snippr.herokuapp.com', 'www.snippr.app')
    window.location.replace(url)
  }

  if (window.location.protocol === 'http') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`)
  }
}
